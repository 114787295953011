import React from 'react';
import classnames from 'classnames';

import { NON_BREAKING_SPACE, TAX_TYPE_LMNP, TAX_TYPE_LMNP_MANAGED } from 'settings';
import {
  LABEL_ACTIVITY_INFOPANEL_OPTIONS,
  LABEL_ACTIVITY_INFOPANEL_SALE,
  LABEL_ACTIVITY_INFOPANEL_TITLE,
  LABEL_ACTIVITY_NO_AIDS,
  LABEL_ACTIVITY_NO_OPTION,
  LABEL_ACTIVITY_SALES_AIDS,
} from 'settings/labels';

import type { Activity } from 'api/vi3pAPI/apiTypes/ActivityType';

import { formatPrice } from 'services/formatter';
import { getActivityChoicesOptions, getActivityChoicesSalesAids } from 'services/activities';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import styles from './ActivityInfoPanel.module.scss';

interface ActivityInfoPanelProps {
  activity: Activity;
  className?: string;
  onClose?: () => void;
  placement?: string;
}

export default React.forwardRef<HTMLDivElement, ActivityInfoPanelProps>(function ActivityInfoPanel(
  { activity, className = undefined, onClose = () => {}, placement },
  ref
) {
  const formattedPrice = formatPrice(
    parseFloat(activity.field_montantvente_ac.replace(/\s/g, ''))
  ).replace(/\s/g, NON_BREAKING_SPACE);

  const salesAids = getActivityChoicesSalesAids(activity);
  const options = getActivityChoicesOptions(activity);

  return (
    <div
      ref={ref}
      className={classnames(className, styles.root, placement ? styles[placement] : undefined)}
    >
      <header className={styles.header}>
        <h3 className={styles.headerTitle}>{LABEL_ACTIVITY_INFOPANEL_TITLE}</h3>
        <button type="button" className={styles.close} onClick={onClose}>
          <Icon icon="cross" />
        </button>
      </header>

      {formattedPrice && (
        <section className={styles.section}>
          <h4 className={styles.sectionTitle}>{LABEL_ACTIVITY_INFOPANEL_SALE}</h4>
          <div className={styles.sectionContent}>
            <Icon className={styles.priceIcon} icon="price-euro" />
            {formattedPrice}{' '}
            {activity.field_dispositiffiscal_ac === TAX_TYPE_LMNP ||
            activity.field_dispositiffiscal_ac === TAX_TYPE_LMNP_MANAGED ? (
              <>HT (prix immo)</>
            ) : (
              <>TTC {activity.field_tva && `TVA ${Number(activity.field_tva)}%`}</>
            )}
          </div>
        </section>
      )}

      <section className={styles.section}>
        <h4 className={styles.sectionTitle}>{LABEL_ACTIVITY_INFOPANEL_OPTIONS}</h4>
        <div className={styles.sectionContent}>
          {options.length === 0 ? (
            LABEL_ACTIVITY_NO_OPTION
          ) : (
            <ul className="checkmark-list">
              {options.map(option => (
                <li key={option.id}>
                  {typeof option.content === 'function'
                    ? option.content({ footnote: styles.footnote })
                    : option.content}
                </li>
              ))}
            </ul>
          )}
        </div>
      </section>

      <section className={styles.section}>
        <h4 className={styles.sectionTitle}>{LABEL_ACTIVITY_SALES_AIDS}</h4>
        <div className={styles.sectionContent}>
          {salesAids.length === 0 ? (
            LABEL_ACTIVITY_NO_AIDS
          ) : (
            <ul className="checkmark-list">
              {salesAids.map(aid => (
                <li key={aid}>{aid}</li>
              ))}
            </ul>
          )}
        </div>
      </section>
    </div>
  );
});
