import React, { useContext, useMemo, useState } from 'react';
import type { ComponentProps, ReactNode } from 'react';

import {
  LABEL_CANCEL_BUTTON,
  LABEL_LAUNCH_ALERT_ENABLE,
  LABEL_MY_SEARCH_MAIL,
  LABEL_MY_SEARCH_MAIL_ERROR,
  LABEL_SAVE_ALERT_MARKETING,
} from 'settings/labels';
import { TAX_TYPE_LMNP } from 'settings/taxes';

import { regexEmail } from 'services/constraints';

import userContext from 'modules/App/Contexts/userContext';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';

import ModalConfirm from 'commonUi/ModalConfirm/ModalConfirm';
import PastilleFiscality from 'commonUi/Pastille/PastilleFiscality';
import TextField from 'commonUi/TextField/TextField';

import styles from './ModalMarketingAlert.module.scss';

interface ModalMarketingAlertProps {
  programData: {
    fiscalite: string;
    postalCode: string | number;
    city: string;
    name: ReactNode;
    programRef: string;
  };
  onClose: () => void;
  open: ComponentProps<typeof ModalConfirm>['open'];
  tmsValues?: {
    open: Record<string, string> | ((programRef: string) => Record<string, string>);
    confirmed: Record<string, string> | ((programRef: string) => Record<string, string>);
  };
}

export default function ModalMarketingAlert({
  onClose,
  open,
  programData,
  tmsValues,
}: ModalMarketingAlertProps) {
  const { createMarketingAlert, userCrm } = useContext(userContext);
  const [email, setEmail] = useState(userCrm?.email);
  const [loading, setLoading] = useState(false);
  const [isToastDisplayed, setIsToastDisplayed] = useState(false);
  const enableConfirm = useMemo(() => !!email && new RegExp(regexEmail).test(email), [email]);

  function handleConfirm() {
    if (email && new RegExp(regexEmail).test(email)) {
      setLoading(true);
      createMarketingAlert(programData.programRef, email, toastType =>
        toastType === 'success'
          ? {
              onEnter: () => setIsToastDisplayed(true),
              onExited: () => setIsToastDisplayed(false),
            }
          : undefined
      ).finally(() => {
        setLoading(false);
        onClose();
      });
    }
  }

  return (
    <>
      <ModalConfirm
        classes={{
          root: styles.modalRoot,
          paper: styles.modalPaper,
          inner: styles.modalInner,
          content: styles.modalContent,
          footer: styles.modalFooter,
          buttonCancel: styles.modalButton,
          buttonConfirm: styles.modalButton,
        }}
        colorCancel="secondary"
        confirmIsDisabled={!enableConfirm}
        labelCancel={LABEL_CANCEL_BUTTON}
        labelConfirm={LABEL_SAVE_ALERT_MARKETING}
        loading={loading}
        onCancel={onClose}
        onConfirm={handleConfirm}
        open={open}
      >
        <div className={styles.header}>
          <Icon className={styles.icon} icon="notifications" />
          <h2 className={styles.title}>{LABEL_LAUNCH_ALERT_ENABLE}</h2>
        </div>
        <div className={styles.program}>
          <div className={styles.name}>{programData.name}</div>
          <div className={styles.info}>
            <PastilleFiscality
              className={styles.pastille}
              tax={programData.fiscalite}
              ignore={TAX_TYPE_LMNP}
            />
            <div className={styles.location}>
              {programData.city} ({programData.postalCode})
            </div>
          </div>
        </div>
        <p className={styles.subtext}>
          Inscrivez-vous pour être alerté par email lors de la commercialisation de cete résidence.{' '}
          <br />
          Soyez le premier à être informé pour bénéficier du meilleur choix.
        </p>
        <form
          onSubmit={ev => {
            ev.preventDefault();
            handleConfirm();
          }}
        >
          <TextField
            required
            id="email"
            type="email"
            error={!enableConfirm}
            helperText={!enableConfirm && LABEL_MY_SEARCH_MAIL_ERROR}
            label={LABEL_MY_SEARCH_MAIL}
            onChange={ev => setEmail(ev.target.value)}
            value={email}
          />
        </form>
      </ModalConfirm>
      {tmsValues?.open && (
        <TagCommanderEvent
          isActive={open && !isToastDisplayed}
          {...(typeof tmsValues.open === 'function'
            ? tmsValues.open(programData.programRef)
            : tmsValues.open)}
        />
      )}
      {tmsValues?.confirmed && (
        <TagCommanderEvent
          isActive={isToastDisplayed}
          {...(typeof tmsValues.confirmed === 'function'
            ? tmsValues.confirmed(programData.programRef)
            : tmsValues.confirmed)}
        />
      )}
    </>
  );
}
