import React from 'react';
import { View } from '@react-pdf/renderer';

import { normalizeTax } from 'services/taxes';
import { formatPrice } from 'services/formatter';
import {
  LABEL_KNOW_MORE,
  LABEL_NUDE_PROPERTY_TTC,
  LABEL_PRICE_IMMO_HT,
  LABEL_PRICE_INCLUDING_TAX_TTC,
  LABEL_PRICE_MOBILIER_HT,
  LABEL_PROFITABILITY,
  LABEL_PROFITABILITY_HT,
  LABEL_RENT_MARKET_MONTH,
  LABEL_RENT_YEARLY,
  LABEL_SECURITY_DEPOSIT,
} from 'settings/labels';
import { LOT_DEFAULT_SECURITY_DEPOSIT } from 'settings/lots';
import {
  TAX_TYPE_DEMEMBREMENT,
  TAX_TYPE_LMNP,
  TAX_TYPE_LMNP_MANAGED,
  TAX_TYPE_NONE,
} from 'settings/taxes';

import { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import PdfRowDetailsList from '../../../Common/PdfRowDetailsList/PdfRowDetailsList';
import PdfDepositInfos from '../../../Common/PdfDepositInfos/PdfDepositInfos';

export default function LotPdfFinancialInfos({ lot, program }: LotPdfFinancialInfosProps) {
  const { fiscalites } = lot;

  const normalizedTax = normalizeTax(fiscalites[0]);
  const isLMNP = normalizedTax === TAX_TYPE_LMNP || normalizedTax === TAX_TYPE_LMNP_MANAGED;
  const isDemembrement = normalizedTax === TAX_TYPE_DEMEMBREMENT;
  const isNoTax = normalizedTax === TAX_TYPE_NONE;
  const {
    bicDepotGarantie,
    ibanDepotGarantie,
    modePaiement,
    nomBanqueDepotGarantie,
    ordreDepotGarantie,
  } = program.depotGarantie;

  let profitability = parseFloat(lot.investissementLocatif.rendementLocatif ?? '');
  if (isLMNP) {
    profitability = parseFloat(lot.investissementLocatif.rentabiliteLMNP ?? '');
  }

  return (
    <View>
      <PdfRowDetailsList
        items={{
          ...(isNoTax
            ? {
                [LABEL_RENT_MARKET_MONTH]: {
                  label: LABEL_RENT_MARKET_MONTH,
                  value: lot?.investissementLocatif?.loyerMarcheTTC
                    ? formatPrice(lot.investissementLocatif.loyerMarcheTTC)
                    : undefined,
                },
              }
            : {}),
          ...(isLMNP
            ? {
                [LABEL_PRICE_IMMO_HT]: {
                  label: LABEL_PRICE_IMMO_HT,
                  value: lot?.prix?.prixHTHorsMobilier
                    ? formatPrice(lot.prix.prixHTHorsMobilier)
                    : undefined,
                },
                [LABEL_PRICE_INCLUDING_TAX_TTC]: {
                  label: LABEL_PRICE_INCLUDING_TAX_TTC,
                  value: formatPrice(lot?.prix?.TVANormale?.prixTTC),
                },
                [LABEL_PRICE_MOBILIER_HT]: {
                  label: LABEL_PRICE_MOBILIER_HT,
                  value: lot?.prix?.prixMobilierHT
                    ? formatPrice(lot.prix.prixMobilierHT)
                    : undefined,
                },
                [LABEL_RENT_YEARLY]: {
                  label: LABEL_RENT_YEARLY,
                  value: lot?.investissementLocatif?.loyerMarcheAnnuelHT
                    ? formatPrice(lot.investissementLocatif?.loyerMarcheAnnuelHT)
                    : undefined,
                },
              }
            : {}),
          ...(isDemembrement
            ? {
                [LABEL_RENT_MARKET_MONTH]: {
                  label: `${LABEL_NUDE_PROPERTY_TTC} = ${LABEL_PRICE_INCLUDING_TAX_TTC}`,
                  value: LABEL_KNOW_MORE,
                },
              }
            : {}),
          [LABEL_PROFITABILITY]: {
            label: isLMNP ? LABEL_PROFITABILITY_HT : LABEL_PROFITABILITY,
            value: profitability > 0 ? `${profitability}%` : '-',
          },
          [LABEL_SECURITY_DEPOSIT]: {
            label: LABEL_SECURITY_DEPOSIT,
            value: program?.depotGarantie?.montantDepotGarantie
              ? formatPrice(program.depotGarantie.montantDepotGarantie)
              : LOT_DEFAULT_SECURITY_DEPOSIT,
            note: (
              <PdfDepositInfos
                modePaiement={modePaiement}
                nomBanqueDepotGarantie={nomBanqueDepotGarantie}
                ordreDepotGarantie={ordreDepotGarantie}
                ibanDepotGarantie={ibanDepotGarantie}
                bicDepotGarantie={bicDepotGarantie}
              />
            ),
          },
        }}
      />
    </View>
  );
}

export type LotPdfFinancialInfosProps = {
  lot: LotTypeV2;
  program: ProgramTypeV2;
};
