import React from 'react';
import type { PropsWithChildren } from 'react';
import classnames from 'classnames';
import Helmet from 'react-helmet';

import SilentRefresh from 'modules/App/SilentRefresh/SilentRefresh';
import FoldersProvider from 'modules/App/Providers/FoldersProvider';
import Footer from './components/Footer/Footer';
import HeadElement from './components/HeadElement';
import HeaderAuthenticated from './components/HeaderAuthenticated/HeaderAuthenticated';

interface AuthenticatedLayoutProps {
  hideFooter?: boolean;
  hideHeader?: boolean;
}

export default function AuthenticatedLayout({
  children,
  hideFooter = false,
  hideHeader = false,
}: PropsWithChildren<AuthenticatedLayoutProps>) {
  return (
    <>
      <SilentRefresh enableTimer />
      <div className="page-layout">
        <FoldersProvider>
          <HeadElement />
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          {!hideHeader && <HeaderAuthenticated />}
          <main role="main" className={classnames({ main__authenticated: !hideHeader })}>
            {children}
          </main>
        </FoldersProvider>
      </div>
      {!hideFooter && <Footer authenticated />}
    </>
  );
}
