import React, { useCallback } from 'react';
import type { ContextType, PropsWithChildren } from 'react';

import { error } from 'services/log';

import ContactsContext from 'modules/App/Contexts/ContactsContext';

import { useSWRVi3pImmutable } from 'api/vi3pAPI/useSWRVi3p';
import { filterNullable } from 'services/array';

type Contacts = ReturnType<ContextType<typeof ContactsContext>['getContacts']>;

function getContact(contacts: Contacts | undefined, email: string) {
  const contact = contacts?.find(contact => contact.email?.toLowerCase() === email.toLowerCase());
  if (!contact) {
    error('contacts', `Unable to match email ${email} to an existing contact`);
  }
  return contact;
}

export default function ContactsProvider({ children }: PropsWithChildren<Record<never, never>>) {
  const { data: contacts, isLoading } = useSWRVi3pImmutable<Contacts>({ url: `/contact` });

  return (
    <ContactsContext.Provider
      value={{
        isLoading,
        getContacts: useCallback(
          (emails, getLinked = false) => {
            if (!emails) {
              return contacts ?? [];
            }
            if (!getLinked) {
              return filterNullable(emails.map(email => getContact(contacts, email)));
            }

            const visitedMails = new Set<string>();
            function handleContact(email: string) {
              if (visitedMails.has(email)) {
                return [];
              }
              const contact = getContact(contacts, email);
              if (!contact?.email) {
                return [];
              }
              visitedMails.add(contact.email);
              if (contact.contact_adv) {
                return [contact, ...handleContact(contact.contact_adv)];
              }
              return [contact];
            }

            return emails.map(email => handleContact(email)).flat();
          },
          [contacts]
        ),
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
}
