import React, { useRef, useState } from 'react';
import classnames from 'classnames';

import type { SliderRef } from 'types/slider';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import FieldImage from 'commonUi/FieldImage/FieldImage';
import SlickSlider from 'commonUi/SlickSlider/SlickSlider';

import styles from './SliderInline.module.scss';

type Image = { url: string; properties: { alt: string; target_uuid: string } } | string;
interface SliderInlineProps {
  classes?: Partial<
    Record<'root' | 'slider' | 'image' | 'dots' | 'dot' | 'dotActive' | 'dotCurrent', string>
  >;
  disableSwipe?: boolean;
  images: Image[];
  lazyLoadImages?: boolean;
  renderArrows?: boolean;
  renderDots?: boolean;
  renderImage?: (imageElement: JSX.Element) => JSX.Element;
}

export default function SliderInline({
  classes = {},
  disableSwipe = false,
  images,
  lazyLoadImages = false,
  renderArrows = false,
  renderDots = true,
  renderImage = x => x,
}: SliderInlineProps) {
  const dotsRef = useRef<SliderRef>();
  const [activeSlide, setActiveSlide] = useState(0);

  if (!images.length) {
    return null;
  }

  if (images.length === 1) {
    const img = images[0];
    const imageUrl = typeof img === 'string' ? img : img.url;
    const imageAlt = typeof img === 'string' ? '' : img.properties.alt;
    return (
      <div className={classnames(classes.root, styles.root)}>
        {renderImage(
          <FieldImage
            className={classnames(classes.image, styles.image)}
            src={imageUrl}
            alt={imageAlt}
          />
        )}
      </div>
    );
  }

  return (
    <div className={classnames(classes.root, styles.root, styles.sliderContainer)}>
      <SlickSlider
        className={classnames(classes.slider, styles.slider)}
        arrows={renderArrows}
        infinite={false}
        swipe={!disableSwipe}
        prevArrow={
          renderArrows ? (
            <button type="button">
              <Icon icon="chevron-down" />
            </button>
          ) : undefined
        }
        nextArrow={
          renderArrows ? (
            <button type="button">
              <Icon icon="chevron-down" />
            </button>
          ) : undefined
        }
        beforeChange={(_: number, next: number) => {
          if (dotsRef.current) {
            setActiveSlide(next);
            dotsRef.current.slickGoTo(next);
          }
        }}
      >
        {images.map(image =>
          renderImage(
            <FieldImage
              key={typeof image === 'string' ? image : image.properties.target_uuid}
              isLazy={lazyLoadImages && !(images.length > 1)}
              className={classnames(classes.image, styles.image)}
              lazyClassName={styles.lazy}
              src={typeof image === 'string' ? image : image.url}
              alt={typeof image === 'string' ? '' : image.properties.alt}
            />
          )
        )}
      </SlickSlider>
      {renderDots && (
        <SlickSlider
          sliderRef={dotsRef}
          className={classnames(classes.dots, styles.dots)}
          centerPadding={0}
          centerMode
          variableWidth
          accessibility={false}
          arrows={false}
          draggable={false}
          infinite={false}
          swipe={false}
          touchMove={false}
        >
          {images.map((_, index) => (
            <div>
              <div
                className={classnames(
                  classes.dot,
                  styles.dot,
                  index === activeSlide ? [classes.dotCurrent, styles.current] : undefined,
                  [activeSlide - 1, activeSlide, activeSlide + 1].includes(index)
                    ? [classes.dotActive, styles.active]
                    : undefined
                )}
              />
            </div>
          ))}
        </SlickSlider>
      )}
    </div>
  );
}
