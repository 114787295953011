import React from 'react';
import classnames from 'classnames';

import { LABEL_KITCHEN_LOT_DETAIL_TITLE } from 'settings/labels';
import { TAX_TYPE_LMNP } from 'settings/taxes';

import { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import {
  programHasKitchen,
  programIsControlledPrice,
  programIsFNO,
  programIsFurnishEligible,
  programIsHonoBoost,
  programIsReducedVAT,
  programIsSenior,
  programIsStudent,
} from 'services/programs';

import KitchenIcon from 'commonUi/KitchenIcon/KitchenIcon';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import PastilleFiscality from 'commonUi/Pastille/PastilleFiscality';

import styles from './ProgramBannerPictos.module.scss';

interface ProgramBannerPictosProps {
  className?: string;
  program: ProgramTypeV2;
}

export default function ProgramBannerPictos({ className, program }: ProgramBannerPictosProps) {
  return (
    <div className={classnames(className, styles.root)}>
      <PastilleFiscality
        className={styles.picto}
        tax={program.informationsFiscalesEtFinancieres.fiscalites[0]}
        ignore={TAX_TYPE_LMNP}
      />
      {programIsReducedVAT(program) && <SvgIcon className={styles.picto} iconId="icon-tva-5-5" />}
      {programIsSenior(program) && <SvgIcon className={styles.picto} iconId="icon-ovelia" />}
      {programIsStudent(program) && <SvgIcon className={styles.picto} iconId="icon-student" />}
      {programHasKitchen(program) && (
        <KitchenIcon iconClassName={styles.picto} title={LABEL_KITCHEN_LOT_DETAIL_TITLE} />
      )}
      {programIsFNO(program) && <SvgIcon className={styles.picto} iconId="icon-fno" />}
      {programIsHonoBoost(program) && <SvgIcon className={styles.picto} iconId="icon-honoboost" />}
      {programIsControlledPrice(program) && (
        <SvgIcon className={styles.picto} iconId="icon-prix-maitrises" />
      )}
      {programIsFurnishEligible(program) && (
        <SvgIcon className={styles.picto} iconId="icon-furnish-eligible" />
      )}
    </div>
  );
}
