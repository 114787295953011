import React, { ReactNode, useContext, useMemo, useState } from 'react';
import type { ComponentProps } from 'react';

import { LOT_CELL_ID_KIND } from 'settings/lots';
import { SORT_ORDER_ASC, SORT_ORDER_DESC } from 'settings/search';

import type { LotJson, LotSortBy, LotSortOrder } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { SelectedLotType } from 'types/lots';

import { getLotHeadings, getProgramPageHeadingsWidth, sortLots } from 'services/lots';
import { programIsFurnishEligible } from 'services/programs';
import { normalizeTax } from 'services/taxes';
import { getColumnsWidths } from 'services/ui';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';
import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import LotTableTablette from './LotTableTablette';
import LotTableDesktop from './LotTableDesktop';

interface ProgramLotsDesktopAndTabletProps {
  headerContent?: ReactNode;
  lots: LotJson[];
  pageTemplate?: string;
  pageTemplateRef?: string;
  program: ProgramTypeV2;
  selectedLots: SelectedLotType[];
  setSelectedLots: (selectedLots: SelectedLotType[]) => void;
  statuses: ComponentProps<typeof LotTableDesktop & typeof LotTableDesktop>['statuses'];
  updateStatus: (lotNid: string, status: any) => void;
}

export default function ProgramLotsDesktopAndTablet({
  headerContent = undefined,
  lots = [],
  pageTemplate = undefined,
  pageTemplateRef = undefined,
  program,
  selectedLots,
  setSelectedLots,
  statuses,
  updateStatus,
}: ProgramLotsDesktopAndTabletProps) {
  const { isTablette } = useContext(ResponsiveContext);
  const { taxesById } = useContext(TaxonomiesContext);
  const [sortBy, setSortBy] = useState<LotSortBy>(LOT_CELL_ID_KIND);
  const [sortOrder, setSortOrder] = useState<LotSortOrder>(SORT_ORDER_ASC);

  const handleOnChangeCheckbox: ComponentProps<typeof LotTableDesktop>['handleOnChangeCheckbox'] &
    ComponentProps<typeof LotTableTablette>['handleOnChangeCheckbox'] = (programRef, lotNumber) => {
    if (
      selectedLots.find(
        selectedLot => selectedLot.programRef === programRef && selectedLot.lotNumber === lotNumber
      )
    ) {
      setSelectedLots(
        selectedLots.filter(
          selectedLot =>
            !(selectedLot.programRef === programRef && selectedLot.lotNumber === lotNumber)
        )
      );
      return;
    }
    setSelectedLots([...selectedLots, { programRef, lotNumber }]);
  };

  const sortedLots = useMemo<LotJson[]>(() => sortLots(lots, sortBy, sortOrder, taxesById), [
    lots,
    sortBy,
    sortOrder,
    taxesById,
  ]);

  const [headings, headingsWidths] = useMemo(() => {
    const dedupedTaxTypes = Array.from(new Set(lots.map(lot => normalizeTax(taxesById[lot.tax]))));
    const headings = getLotHeadings(
      false,
      dedupedTaxTypes.length === 1 ? dedupedTaxTypes[0] : undefined,
      true,
      true,
      isTablette ? 'tablet' : 'desktop',
      dedupedTaxTypes.length > 1,
      programIsFurnishEligible(program)
    );
    return [
      headings,
      getColumnsWidths(
        headings,
        getProgramPageHeadingsWidth(dedupedTaxTypes.length === 1 ? dedupedTaxTypes[0] : undefined)
      ),
    ];
  }, [isTablette, lots, program, taxesById]);

  if (!taxesById) {
    return null;
  }

  return (
    <>
      {isTablette ? (
        <LotTableTablette
          headerContent={headerContent}
          headings={headings}
          hideMapButton
          lots={sortedLots}
          handleOnChangeCheckbox={handleOnChangeCheckbox}
          selectedLots={selectedLots}
          pageTemplate={pageTemplate}
          pageTemplateRef={pageTemplateRef}
          statuses={statuses}
          updateStatus={updateStatus}
        />
      ) : (
        <LotTableDesktop
          headerContent={headerContent}
          headings={headings}
          headingsWidth={headingsWidths}
          data={sortedLots}
          reorder={heading => {
            if (!heading.sort) {
              return;
            }
            if (heading.sort === sortBy) {
              if (sortOrder === SORT_ORDER_ASC) {
                setSortOrder(SORT_ORDER_DESC);
              } else {
                setSortOrder(SORT_ORDER_ASC);
              }
            } else {
              setSortBy(heading.sort);
              setSortOrder(SORT_ORDER_ASC);
            }
          }}
          sortBy={sortBy}
          sortOrder={sortOrder}
          handleOnChangeCheckbox={handleOnChangeCheckbox}
          selectedLots={selectedLots}
          pageTemplate={pageTemplate}
          pageTemplateRef={pageTemplateRef}
          program={program}
          statuses={statuses}
          updateStatus={updateStatus}
        />
      )}
    </>
  );
}
