import React from 'react';
import type { ComponentProps, PropsWithChildren } from 'react';

import Footer from './components/Footer/Footer';
import HeadElement from './components/HeadElement';
import HeaderGuest from './components/HeaderGuest/HeaderGuest';

interface GuestLayoutProps {
  isHeaderVisible?: ComponentProps<typeof HeaderGuest>['visible'];
  onScrollerClick?: ComponentProps<typeof HeaderGuest>['onScrollerClick'];
}

export default function GuestLayout({
  children,
  isHeaderVisible,
  onScrollerClick,
}: PropsWithChildren<GuestLayoutProps>) {
  return (
    <>
      <HeadElement />
      <HeaderGuest visible={isHeaderVisible} onScrollerClick={onScrollerClick} />
      <div className="page-layout">
        <main role="main">{children}</main>
      </div>
      <Footer />
    </>
  );
}
