import React from 'react';
import classnames from 'classnames';

import type { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { TaxTaxonomyTerm } from 'api/viOffresAPI/apiTypes/Taxonomies';

import LotDetailsAdditional from './LotDetailsAdditional';
import LotDetailsFinancial from './LotDetailsFinancial';
import LotDetailsInformationsGeneral from './LotDetailsInformationsGeneral';

import styles from './LotDetails.module.scss';

interface LotDetailsInformationsProps {
  isOdd?: boolean;
  lotFromApi?: LotTypeV2;
  programFromApi?: ProgramTypeV2;
  fiscalities: TaxTaxonomyTerm[];
}

export default function LotDetailsInformations({
  isOdd = false,
  fiscalities,
  lotFromApi,
  programFromApi,
}: LotDetailsInformationsProps) {
  const fiscality = fiscalities?.find(fiscality => fiscality.nom === lotFromApi?.fiscalites[0]);

  if (!programFromApi || !lotFromApi) return null;

  return (
    <div className={classnames(styles.root, { [styles.odd]: isOdd })}>
      <div className={classnames(styles.col, styles.colInfos)}>
        <LotDetailsInformationsGeneral
          classes={{ root: styles.infos, title: styles.infosTitle }}
          fiscality={fiscality}
          lot={lotFromApi}
          program={programFromApi}
        />
      </div>

      <div className={classnames(styles.col, styles.colAdditionalInfos)}>
        <LotDetailsAdditional
          classes={{ root: styles.infos, title: styles.infosTitle }}
          lot={lotFromApi}
        />
      </div>
      <div className={classnames(styles.col, styles.colFinancialInfos)}>
        <LotDetailsFinancial
          classes={{ root: styles.infos, title: styles.infosTitle }}
          fiscality={fiscality}
          lot={lotFromApi}
          program={programFromApi}
        />
      </div>
    </div>
  );
}
