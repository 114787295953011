import React from 'react';

import { LABEL_NEWS_TITLE } from 'settings/labels';

import StandardPage from 'layout/components/StandardPage/StandardPage';
import TagCommander from 'modules/App/TagCommander/TagCommander';
import News from 'modules/News';

import styles from './ActualityPage.module.scss';

function ActualityPage() {
  return (
    <StandardPage
      showTitle
      classes={{ root: styles.root, title: styles.title }}
      title={LABEL_NEWS_TITLE}
    >
      <News className={styles.news} visibleItems={Infinity} />

      <TagCommander navigation_pagename="actualites" navigation_template="actualites" />
    </StandardPage>
  );
}

export default ActualityPage;
