import React, { useContext, useMemo } from 'react';
import classnames from 'classnames';

import { extractVideoIdFromUrl } from 'services/videos';

import SettingsContext from 'modules/App/Contexts/SettingsContext';

import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';
import YouTube from 'commonUi/YouTube/YouTube';

import styles from './MediaBlock.module.scss';

const SIGNUP_URL = window.vinci.REACT_APP_SIGNUP_URL;

interface MediaBlockProps {
  className?: string;
}

export default function MediaBlock({ className }: MediaBlockProps) {
  const { settings } = useContext(SettingsContext);

  const { text, iconsList, imageSrc, videoId, videoPreview } = useMemo(
    () => ({
      text: settings.home.block_media.texte,
      iconsList: settings.home.block_media.liste_avec_pictogrammes,
      imageSrc:
        settings.home.block_media.media?.type === 'image'
          ? settings.home.block_media.media.url
          : undefined,
      videoId:
        settings.home.block_media.media?.type === 'video' && settings.home.block_media.media.url
          ? extractVideoIdFromUrl(settings.home.block_media.media.url)
          : undefined,
      videoPreview: settings.home.block_media.media_preview ?? undefined,
    }),
    [settings.home?.block_media]
  );

  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.content}>
        {text && <FieldRte className={styles.text} html={text} />}
        {iconsList.length > 0 && (
          <ul className={styles.pictosList}>
            {iconsList.map(({ texte: text, pictogramme: picto }) => (
              <li key={picto}>
                <img className={styles.picto} src={picto} alt="" />
                <FieldRte className={styles.text} html={text} />
              </li>
            ))}
          </ul>
        )}
        <a className={classnames(styles.button, 'button')} href={SIGNUP_URL}>
          Je deviens partenaire
        </a>
      </div>
      <div className={styles.media}>
        {videoId && (
          <YouTube
            className={styles.video}
            videoId={videoId}
            preview={videoPreview}
            opts={{
              height: '100%',
              width: '100%',
              playerVars: {
                controls: 0,
                enablejsapi: 1,
                rel: 0,
              },
            }}
          />
        )}
        {!videoId && imageSrc && <img className={styles.image} src={imageSrc} alt="" />}
      </div>
    </div>
  );
}
