import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { IconButton } from '@material-ui/core';

import { getFirstPromotion } from 'services/promotions';
import { testMobilePhone } from 'services/yup';
import {
  FIELD_LOT_PRICE,
  KEY_PREBOOK_PURCHASE_DESTINATION_FIRSTIME_BUYER,
  KEY_PREBOOK_PURCHASE_DESTINATION_INVESTOR,
  KEY_PREBOOK_PURCHASE_DESTINATION_PRINCIPAL_RESIDENCE,
  KEY_PREBOOK_PURCHASE_DESTINATION_SECOND_RESIDENCE,
  KEY_VAT_NORMAL_PRICE,
  KEY_VAT_REDUCED_PRICE,
} from 'settings/forms';
import {
  LABEL_CANCEL_BUTTON,
  LABEL_CONFIRM_PRE_BOOK,
  LABEL_EMAIL,
  LABEL_EMAIL_ERROR,
  LABEL_FIRSTNAME,
  LABEL_FIRSTNAME_ERROR,
  LABEL_FISCALITY,
  LABEL_FORM_ERROR_CONTENT,
  LABEL_FORM_ERROR_DEFAULT,
  LABEL_FORM_FIELDS_REQUIRED,
  LABEL_FORM_PREBOOK_ERROR_MESSAGE_DEGRADED_CRM,
  LABEL_FORM_PREBOOK_ERROR_TITLE,
  LABEL_FORM_PREBOOK_ERROR_TITLE_DEGRADED_CRM,
  LABEL_FORM_PREBOOK_INVESTOR,
  LABEL_FORM_PREBOOK_PRINCIPAL_RESIDENCE,
  LABEL_FORM_PREBOOK_SECOND_RESIDENCE,
  LABEL_GENERAL_INFORMATIONS,
  LABEL_HT,
  LABEL_LASTNAME,
  LABEL_LASTNAME_ERROR,
  LABEL_LOT_PRICE,
  LABEL_MADAME,
  LABEL_MISTER,
  LABEL_MOBILE_PHONE,
  LABEL_NO,
  LABEL_PHONE_NUMBER_ERROR,
  LABEL_PHONE_NUMBER_MOBILE_ERROR,
  LABEL_PHONE_NUMBER_MOBILE_FORMAT_TEST,
  LABEL_PROMOTION_ACTIVITIES_FORM,
  LABEL_PURCHASER,
  LABEL_PURCHASE_DESTINATION,
  LABEL_SIGNATURE_DATE,
  LABEL_TTC,
} from 'settings/labels';
import {
  TAX_TYPE_BRS,
  TAX_TYPE_DEMEMBREMENT,
  TAX_TYPE_LMNP,
  TAX_TYPE_LMNP_MANAGED,
  TAX_TYPE_MALRAUX,
  TAX_TYPE_NONE,
  TAX_TYPE_PLS,
} from 'settings/taxes';
import { TOKEN_LOT_NUMBER, TOKEN_PROGRAM_NAME } from 'settings/token';
import { USER_GENDER_FEMALE, USER_GENDER_MALE } from 'settings/user';

import type { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import userContext from 'modules/App/Contexts/userContext';

import { regexEmail } from 'services/constraints';
import { addDays, subDays } from 'services/date';
import { formatPrice, replaceTokens } from 'services/formatter';
import { normalizeTax, taxPayloadNames } from 'services/taxes';

import { useCreatePreBooking } from 'hooks/useActivities';

import RadioGroup from 'commonUi/RadioButton/RadioGroup';
import RadioLabel from 'commonUi/RadioButton/RadioLabel';
import TextField from 'commonUi/TextField/TextField';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import Button from 'commonUi/Button/Button';
import PhoneField from 'commonUi/PhoneField/PhoneField';
import SelectField from 'commonUi/SelectField/SelectField';
import DateField from 'commonUi/DateField/DateField';
import PastilleFiscality from 'commonUi/Pastille/PastilleFiscality';

import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';
import { FieldFiscality } from '../Fields/FieldFiscality';
import { FieldPtz } from '../Fields/FieldPtz';

import styles from '../PageAction.module.scss';

interface FormPrebookingType {
  handleClose: () => void;
  lot: LotTypeV2 | undefined;
  program: ProgramTypeV2 | undefined;
  setSuccessData: (responseData: any) => void;
}

export function FormPrebooking({ handleClose, setSuccessData, lot, program }: FormPrebookingType) {
  const { contactPrescriptor } = useContext(userContext);

  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorTitle, setErrorTitle] = useState(LABEL_FORM_PREBOOK_ERROR_TITLE);
  const [errorMessage, setErrorMessage] = useState(LABEL_FORM_ERROR_CONTENT);
  const [countryCode, setCountryCode] = useState('FR');

  const createPreBooking = useCreatePreBooking();

  const methods = useForm({
    resolver: yupResolver(formPrebookSchema(countryCode)),
    mode: 'onChange',
  });

  const { control, handleSubmit, formState, watch } = methods;

  const { errors } = formState;
  const { isDirty, isValid } = formState;

  const normalizedTax = normalizeTax(lot?.fiscalites[0]);
  const promotions = lot?.promotions && getFirstPromotion(lot.promotions, false);

  const submitHandler: Parameters<typeof handleSubmit>[0] = async values => {
    setLoading(true);
    const lotTaxPayload = normalizedTax ? taxPayloadNames[normalizedTax] : undefined;
    const dispositifFiscal = values['field-fiscality'] || lotTaxPayload;
    const normalVAT = lot?.prix.TVANormale.tauxTva
      ? parseFloat(lot?.prix.TVANormale.tauxTva)
      : undefined;
    const altVAT = lot?.prix.TVAalternative?.tauxTva
      ? parseFloat(lot.prix.TVAalternative.tauxTva)
      : undefined;
    return createPreBooking(
      {
        civility: values['field-civility'],
        lastName: values['field-lastname'],
        firstName: values['field-firstname'],
        email: values['field-email'],
        phone: values['field-phone-number']
          ? `+${values['field-phone-number']}`
          : values['field-phone-number'],
        destination: values['field-purchase-destination'],
      },
      dispositifFiscal,
      values['field-ptz'] ? values['field-ptz'] : false,
      values[FIELD_LOT_PRICE] === 'normalPrice' ? normalVAT : altVAT,
      lot?.reference as string,
      program?.referenceProgramme as string,
      values['field-signature-date'].toISOString()
    )
      .then(response => {
        setSuccessData(response?.data);
      })
      .catch(error => {
        if (error?.response?.status) {
          const { status } = error.response;
          const errorMessage = error?.response?.data?.message;
          if (errorMessage) {
            if (status === 202) {
              setErrorTitle(LABEL_FORM_PREBOOK_ERROR_TITLE_DEGRADED_CRM);
              setErrorMessage(
                replaceTokens(LABEL_FORM_PREBOOK_ERROR_MESSAGE_DEGRADED_CRM, {
                  [TOKEN_LOT_NUMBER]: lot?.reference,
                  [TOKEN_PROGRAM_NAME]: program?.nomCommercial,
                })
              );
            }
            setErrorMessage(errorMessage);
          }
          setApiError(true);
        }
      })
      .finally(() => setLoading(false));
  };

  function getLotPriceOptions() {
    const options: { key: string; value: string }[] = [];
    switch (normalizeTax(lot?.fiscalites[0])) {
      case TAX_TYPE_DEMEMBREMENT:
        options.push({
          value: `${formatPrice(lot?.prix.TVANormale.prixTTC)} ${LABEL_TTC} TVA ${
            lot?.prix.TVANormale.tauxTva
          } %`,
          key: 'normalPrice',
        });
        break;

      case TAX_TYPE_LMNP_MANAGED:
        options.push({
          value: `${formatPrice(lot?.prix.prixHTHorsMobilier)} ${LABEL_HT} TVA ${
            lot?.prix.TVANormale.tauxTva
          } %`,
          key: KEY_VAT_NORMAL_PRICE,
        });
        break;

      case TAX_TYPE_LMNP:
      case TAX_TYPE_NONE:
        if (lot?.prix.TVANormale.prixTTC) {
          const { prixTTC: price, tauxTva: rate } = lot.prix.TVANormale;
          options.push({
            value: `${formatPrice(price)} TTC TVA ${rate} %`,
            key: KEY_VAT_NORMAL_PRICE,
          });
        }

        if (lot?.prix.TVAalternative?.prixTTC) {
          const { prixTTC: price, tauxTva: rate } = lot.prix.TVAalternative;
          options.push({
            value: `${formatPrice(price)} TTC TVA ${rate} %`,
            key: KEY_VAT_REDUCED_PRICE,
          });
        }
        break;

      case TAX_TYPE_MALRAUX:
        options.push({
          value: `${formatPrice(lot?.prix.prixHTHorsMobilier)} TTC`,
          key: KEY_VAT_NORMAL_PRICE,
        });
        break;

      default:
        break;
    }

    return options;
  }

  function getPurchaseDestinationDefaultValue() {
    if (
      normalizedTax &&
      [
        TAX_TYPE_LMNP,
        TAX_TYPE_LMNP_MANAGED,
        TAX_TYPE_DEMEMBREMENT,
        TAX_TYPE_BRS,
        TAX_TYPE_PLS,
      ].includes(normalizedTax)
    ) {
      return KEY_PREBOOK_PURCHASE_DESTINATION_INVESTOR;
    }
    return '';
  }

  const purchaseDestination = watch('field-purchase-destination');

  const isPtzDisplay =
    [
      KEY_PREBOOK_PURCHASE_DESTINATION_FIRSTIME_BUYER,
      KEY_PREBOOK_PURCHASE_DESTINATION_PRINCIPAL_RESIDENCE,
    ].includes(purchaseDestination) &&
    ![TAX_TYPE_LMNP, TAX_TYPE_LMNP_MANAGED, TAX_TYPE_DEMEMBREMENT, undefined].includes(
      normalizedTax
    );

  if (!normalizedTax) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className={styles.form}>
          <div className={styles.title}>{LABEL_PURCHASER}</div>
          <div className={styles.input}>
            <Controller
              name="field-civility"
              control={control}
              defaultValue={USER_GENDER_MALE}
              render={props => (
                <RadioGroup
                  value={props.field?.value}
                  onChange={ev => props?.field?.onChange(ev.target.value)}
                  radioLabel={
                    <>
                      <RadioLabel value={USER_GENDER_MALE} label={LABEL_MISTER} />
                      <RadioLabel value={USER_GENDER_FEMALE} label={LABEL_MADAME} />
                    </>
                  }
                />
              )}
            />
          </div>
          <div className={styles.grid}>
            <div className={styles.input}>
              <Controller
                name="field-lastname"
                control={control}
                render={props => (
                  <TextField
                    error={errors?.['field-lastname']}
                    helperText={errors?.['field-lastname']?.message}
                    label={LABEL_LASTNAME}
                    onChange={ev => props?.field.onChange(ev.target.value)}
                    required
                    value={props?.field.value}
                  />
                )}
              />
            </div>
            <div className={styles.input}>
              <Controller
                name="field-firstname"
                control={control}
                render={props => (
                  <TextField
                    error={errors?.['field-firstname']}
                    helperText={errors?.['field-firstname']?.message}
                    label={LABEL_FIRSTNAME}
                    onChange={ev => props?.field.onChange(ev.target.value)}
                    required
                    value={props?.field.value}
                  />
                )}
              />
            </div>
            <div className={styles.input}>
              <Controller
                name="field-email"
                control={control}
                render={props => (
                  <TextField
                    error={errors?.['field-email']}
                    helperText={errors?.['field-email']?.message}
                    label={LABEL_EMAIL}
                    onChange={ev => props?.field.onChange(ev.target.value)}
                    required
                    value={props?.field.value}
                  />
                )}
              />
            </div>
            <div className={styles.input}>
              <Controller
                name="field-phone-number"
                control={control}
                render={props => (
                  <PhoneField
                    country="fr"
                    error={errors?.['field-phone-number']}
                    helperText={errors?.['field-phone-number']?.message}
                    id="telephone"
                    inputProps={{
                      name: 'field-phone-number',
                      required: true,
                    }}
                    onChangeInput={ev => props?.field.onChange(ev.target.value)}
                    placeholder={LABEL_MOBILE_PHONE}
                    setCountryCode={setCountryCode}
                    value={props?.field.value}
                  />
                )}
              />
            </div>
          </div>
          <div className={styles.title}>{LABEL_GENERAL_INFORMATIONS}</div>
          <div className={styles.grid}>
            <div className={styles.input}>
              <Controller
                name="field-purchase-destination"
                control={control}
                defaultValue={getPurchaseDestinationDefaultValue()}
                render={props => (
                  <SelectField
                    id="field-purchase-destination"
                    label={LABEL_PURCHASE_DESTINATION}
                    onChange={optionValue => props?.field.onChange(optionValue)}
                    options={[
                      {
                        value: LABEL_FORM_PREBOOK_INVESTOR,
                        key: KEY_PREBOOK_PURCHASE_DESTINATION_INVESTOR,
                      },
                      {
                        value: LABEL_FORM_PREBOOK_PRINCIPAL_RESIDENCE,
                        key: KEY_PREBOOK_PURCHASE_DESTINATION_PRINCIPAL_RESIDENCE,
                      },
                      {
                        value: LABEL_FORM_PREBOOK_SECOND_RESIDENCE,
                        key: KEY_PREBOOK_PURCHASE_DESTINATION_SECOND_RESIDENCE,
                      },
                    ]}
                    required
                    value={props?.field.value}
                  />
                )}
              />
            </div>
            <div className={classNames(styles.input, { [styles.displayColumn]: promotions })}>
              <Controller
                name="field-lot-price"
                control={control}
                defaultValue="normalPrice"
                render={props => (
                  <SelectField
                    id="field-lot-price"
                    label={LABEL_LOT_PRICE}
                    onChange={optionValue => {
                      props?.field.onChange(optionValue);
                    }}
                    options={getLotPriceOptions()}
                    required
                    value={props?.field.value}
                  />
                )}
              />
              {promotions && (
                <div className={styles.infoText}>{LABEL_PROMOTION_ACTIVITIES_FORM}</div>
              )}
            </div>
            <div className={styles.input}>
              <span className={styles.subtitle}>{LABEL_FISCALITY}</span>
              {normalizedTax === TAX_TYPE_NONE ? (
                <span className={styles.noFiscality}>{LABEL_NO}</span>
              ) : (
                <>
                  <PastilleFiscality className={styles.pastille} tax={lot?.fiscalites[0]} />
                  {normalizedTax === TAX_TYPE_LMNP && (
                    <FieldFiscality normalizedTax={normalizedTax} />
                  )}
                </>
              )}
              <FieldPtz noLabel name="field-ptz" show={isPtzDisplay} />
            </div>
          </div>
          <div className={styles.title}>{LABEL_SIGNATURE_DATE}</div>
          <div className={styles.grid}>
            <div className={styles.input}>
              <Controller
                name="field-signature-date"
                control={control}
                defaultValue={new Date()}
                render={props => (
                  <DateField
                    id="field-signature-date"
                    label={LABEL_SIGNATURE_DATE}
                    name="field-signature-date"
                    onChange={ev => {
                      props?.field.onChange(ev.target.value);
                    }}
                    required
                    value={props?.field.value}
                    minDate={subDays(new Date(), contactPrescriptor?.dureePrereservations ?? 5)}
                    maxDate={addDays(
                      new Date(),
                      Number(contactPrescriptor?.dureePrereservations ?? 5) - 1
                    )}
                  />
                )}
              />
            </div>
          </div>

          {!apiError && (
            <span className={styles.additionalInformation}>* {LABEL_FORM_FIELDS_REQUIRED}</span>
          )}
        </div>
        <div className={classNames(styles.footer, { [styles.errorFooter]: apiError })}>
          {apiError && (
            <div className={styles.errorContent}>
              <div className={styles.right}>
                <IconButton
                  classes={{ root: styles.iconButton }}
                  disableRipple
                  onClick={() => setApiError(false)}
                >
                  <SvgIcon className={styles.svgIcon} iconId="icon-cross" />
                </IconButton>
              </div>
              <div className={styles.center}>
                <h4>{errorTitle}</h4>
                <p>{errorMessage}</p>
                <p>{LABEL_FORM_ERROR_DEFAULT}</p>
              </div>
            </div>
          )}
          {!apiError && (
            <>
              <div className={styles.cancelButton}>
                <Button fullWidth variant="contained" color="secondary" onClick={handleClose}>
                  {LABEL_CANCEL_BUTTON}
                </Button>
              </div>
              <div className={styles.createButton}>
                <Button
                  disabled={!isDirty || !isValid}
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={loading}
                >
                  {LABEL_CONFIRM_PRE_BOOK}
                </Button>
              </div>
            </>
          )}
        </div>
      </form>
      <TagCommanderEvent
        isActive={apiError}
        navigation_pagename="form_prereservation.erreur"
        navigation_template="prereservation"
        useEffectDeps={['navigation_template', 'navigation_pagename']}
      />
    </FormProvider>
  );
}

const formPrebookSchema = (countryCode: string) => {
  return yup.object().shape({
    'field-civility': yup.string().trim().required(),
    'field-lastname': yup.string().trim().required(LABEL_LASTNAME_ERROR),
    'field-firstname': yup.string().trim().required(LABEL_FIRSTNAME_ERROR),
    'field-email': yup.string().required().matches(RegExp(regexEmail), LABEL_EMAIL_ERROR),
    'field-phone-number': yup
      .string()
      .trim()
      .required(LABEL_PHONE_NUMBER_ERROR)
      .test(LABEL_PHONE_NUMBER_MOBILE_FORMAT_TEST, LABEL_PHONE_NUMBER_MOBILE_ERROR, val =>
        testMobilePhone(val, countryCode)
      ),
    'field-purchase-destination': yup.string().trim().required(),
    [FIELD_LOT_PRICE]: yup.string().trim().required(),
    'field-fiscality': yup.string().trim(),
    'field-ptz': yup.boolean(),
    'field-signature-date': yup.date().required(),
  });
};
