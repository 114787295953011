import React, { useMemo } from 'react';
import type { ComponentProps } from 'react';

import type { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { TaxTaxonomyTerm } from 'api/viOffresAPI/apiTypes/Taxonomies';

import { LotButtons } from 'commonUi/ListLotV2/LotButtons/LotButtons';
import { LotDetailLayoutDesktop } from 'commonUi/ListLotV2/LotDetailLayout/LotDetailLayoutDesktop';
import LotDetailsInformations from 'commonUi/ListLotV2/LotDetails/LotDetailsInformations';
import LotDetailsImage from 'commonUi/ListLotV2/LotDetails/LotDetailsImage';
import LotPromotion from 'commonUi/ListLotV2/LotPromotion/LotPromotion';
import TagCommanderComponent from 'modules/App/TagCommander/TagCommanderComponent';

interface LotDetailCpnProps {
  myOptions: ComponentProps<typeof LotButtons>['myOptions'];
  myBookings: ComponentProps<typeof LotButtons>['myBookings'];
  index: number;
  lotApiOffre: LotTypeV2;
  programDatas: ProgramTypeV2;
  fiscalities: TaxTaxonomyTerm[];
  statuses: ComponentProps<typeof LotButtons>['statuses'];
  updateStatus: ComponentProps<typeof LotButtons>['updateStatus'];
  pageTemplate?: string;
  pageTemplateRef?: string;
  openDropdownTop?: boolean;
  openPanel: ComponentProps<typeof LotButtons>['openPanel'];
}

export function LotDetailsCpn({
  myOptions,
  myBookings,
  index,
  fiscalities,
  statuses = {},
  lotApiOffre,
  programDatas,
  updateStatus = undefined,
  pageTemplate = undefined,
  pageTemplateRef = undefined,
  openDropdownTop = false,
  openPanel,
}: LotDetailCpnProps) {
  const lotJson = useMemo(
    () => ({
      ref: programDatas.referenceProgramme,
      number: lotApiOffre.reference,
      nid: lotApiOffre.id.toString(),
      status: lotApiOffre.statut,
      program: {
        name: programDatas.nomCommercial,
        ref: programDatas.referenceProgramme,
      },
    }),
    [lotApiOffre]
  );

  if (!lotApiOffre) {
    return null;
  }

  return (
    <>
      <LotDetailLayoutDesktop
        isOdd={index % 2 !== 0}
        renderButton={() => (
          <LotButtons
            program={programDatas}
            myBookings={myBookings}
            myOptions={myOptions}
            isOdd={index % 2 !== 0}
            lot={lotJson}
            lotFromApi={lotApiOffre}
            statuses={statuses}
            updateStatus={updateStatus}
            pageTemplate={pageTemplate}
            pageTemplateRef={pageTemplateRef}
            openDropdownTop={openDropdownTop}
            openPanel={openPanel}
          />
        )}
        renderDetails={() => (
          <LotDetailsInformations
            lotFromApi={lotApiOffre}
            programFromApi={programDatas}
            fiscalities={fiscalities}
            isOdd={index % 2 !== 0}
          />
        )}
        renderImage={() => (
          <LotDetailsImage lotFromApi={lotApiOffre} programFromApi={programDatas} isNoFno />
        )}
        renderPromotion={className => <LotPromotion className={className} lot={lotApiOffre} />}
      />

      {pageTemplateRef && pageTemplate && (
        <TagCommanderComponent
          navigation_pagename={`${pageTemplateRef}.${lotJson.number}`}
          navigation_template={pageTemplate}
          useEffectDeps={['navigation_template', 'navigation_pagename']}
        />
      )}
    </>
  );
}
