import {
  TAX_LABEL_TYPE_BRS,
  TAX_LABEL_TYPE_DEFICIT,
  TAX_LABEL_TYPE_DEMEMBREMENT,
  TAX_LABEL_TYPE_LMNP,
  TAX_LABEL_TYPE_MALRAUX,
  TAX_LABEL_TYPE_NONE,
  TAX_LABEL_TYPE_PLS,
  TAX_TYPE_BRS,
  TAX_TYPE_DEFICIT,
  TAX_TYPE_DEMEMBREMENT,
  TAX_TYPE_LMNP,
  TAX_TYPE_LMNP_MANAGED,
  TAX_TYPE_MALRAUX,
  TAX_TYPE_NONE,
  TAX_TYPE_PLS,
} from 'settings/taxes';

import type { TaxType } from 'api/viOffresAPI/apiTypes/Taxonomies';

const taxMapping = new Map<string, TaxType>([
  ['deficitfoncier', TAX_TYPE_DEFICIT],
  ['demembrement', TAX_TYPE_DEMEMBREMENT],
  ['démembrement', TAX_TYPE_DEMEMBREMENT],
  ['lmnp', TAX_TYPE_LMNP],
  ['lmnp géré', TAX_TYPE_LMNP_MANAGED],
  ['lmnp geré', TAX_TYPE_LMNP_MANAGED],
  ['lmnp gere', TAX_TYPE_LMNP_MANAGED],
  ['lmnp gére', TAX_TYPE_LMNP_MANAGED],
  ['residencehorspinel', TAX_TYPE_NONE],
  ['hors dispositif', TAX_TYPE_NONE],
  ['aucun', TAX_TYPE_NONE],
  ['non', TAX_TYPE_NONE],
  ['brs', TAX_TYPE_BRS],
  ['pls', TAX_TYPE_PLS],
  ['malraux', TAX_TYPE_MALRAUX],
]);
export function normalizeTax(tax: string | undefined) {
  if (tax !== undefined) {
    return taxMapping.get(tax.toLowerCase());
  }
  return tax;
}

export const taxLabelMapping: Map<TaxType, string> = new Map([
  [TAX_TYPE_DEFICIT, TAX_LABEL_TYPE_DEFICIT],
  [TAX_TYPE_DEMEMBREMENT, TAX_LABEL_TYPE_DEMEMBREMENT],
  [TAX_TYPE_LMNP, TAX_LABEL_TYPE_LMNP],
  [TAX_TYPE_NONE, TAX_LABEL_TYPE_NONE],
  [TAX_TYPE_BRS, TAX_LABEL_TYPE_BRS],
  [TAX_TYPE_PLS, TAX_LABEL_TYPE_PLS],
  [TAX_TYPE_MALRAUX, TAX_LABEL_TYPE_MALRAUX],
]);

/** Those values are the ones sent in request payloads */
export const taxPayloadNames = {
  [TAX_TYPE_DEFICIT]: 'deficitFoncier',
  [TAX_TYPE_DEMEMBREMENT]: 'Demembrement',
  [TAX_TYPE_LMNP]: 'LMNP',
  [TAX_TYPE_LMNP_MANAGED]: 'LMNP gere',
  [TAX_TYPE_MALRAUX]: 'Malraux',
  [TAX_TYPE_BRS]: 'BRS',
  [TAX_TYPE_PLS]: 'PLS',
  [TAX_TYPE_NONE]: 'aucun',
} as const;
