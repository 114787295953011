import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { CircularProgress as MuiCircularProgress } from '@material-ui/core';
import moment from 'moment';

import { LABEL_CREATED_WITH_DATE, LABEL_PRINT } from 'settings/labels';
import { TOKEN_DATE } from 'settings/token';

import type { ActualityType } from 'api/vi3pAPI/apiTypes/ActualityType';

import { replaceTokens } from 'services/formatter';

import { useSWRVi3p } from 'api/vi3pAPI/useSWRVi3p';

import backgroundUrl from 'images/placeholders/1665x937.png';
import iconPrint from 'images/icons/icon_print-white.svg';

import { ErrorPage404 } from 'layout/components/ErrorPage/ErrorPage';
import TagCommander from 'modules/App/TagCommander/TagCommander';

import ActualityDetailLots from './ActualityDetailLots';
import SubActuality from './SubActuality';

import styles from './ActualityDetail.module.scss';

export default function ActualityDetail({ match }: RouteComponentProps<{ id: string }>) {
  const { data: actuality, isValidating } = useSWRVi3p<ActualityType>({
    url: match.params.id ? `actualites/${match.params.id}` : undefined,
  });

  if (!actuality) {
    if (!isValidating) {
      return <ErrorPage404 />;
    }
    return (
      <div className={styles.loading}>
        <MuiCircularProgress variant="indeterminate" size={42} />
      </div>
    );
  }

  const { date, introduction: intro, lots, photo, sactu, titre: title } = actuality;

  return (
    <>
      <section className={styles.root}>
        <div className={styles.banner}>
          <div className={styles.detailImgWrapper}>
            <img
              className={styles.detailImg}
              src={photo?.detail_article || backgroundUrl}
              alt="Vinci"
              title="Vinci"
            />
            <div className={styles.opacity} />
            <h1 className={styles.title}>{title}</h1>
            <button type="button" className={styles.print} onClick={() => window.print()}>
              <img src={iconPrint} alt={LABEL_PRINT} />
            </button>
          </div>
        </div>
        <div className={styles.main}>
          <div className={styles.content}>
            <div className={styles.date}>
              {replaceTokens(LABEL_CREATED_WITH_DATE, {
                [TOKEN_DATE]: moment.unix(date).format('DD/MM/YYYY'),
              })}
            </div>
            <p className={styles.desc}>{intro}</p>
            <hr className={styles.separation} />
            {sactu &&
              Object.entries(sactu).map(([subActuId, subActu]) => (
                <SubActuality key={subActuId} actu={subActu} />
              ))}
            <ActualityDetailLots lots={lots} />
          </div>
        </div>
      </section>
      <TagCommander
        navigation_pagename={`actualite.${actuality.titre}`}
        navigation_template="actualites"
      />
    </>
  );
}
