import React from 'react';
import type { PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from './StandardPage.module.scss';

interface StandarPageProps {
  className?: string;
  classes?: Partial<Record<'root' | 'title', string>>;
  showTitle?: boolean;
  title?: string;
}

export default function StandardPage({
  children,
  className,
  classes = {},
  showTitle = false,
  title,
}: PropsWithChildren<StandarPageProps>) {
  return (
    <div className={classnames(className, classes.root, styles.root)}>
      <h1 className={classnames(styles.title, classes.title, showTitle && styles.show)}>{title}</h1>
      {children}
    </div>
  );
}
