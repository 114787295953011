import React from 'react';

import { LABEL_FROM, LABEL_HT, LABEL_PRICE_OF, LABEL_TO, LABEL_TTC } from 'settings/labels';
import { TAX_TYPE_LMNP, TAX_TYPE_LMNP_MANAGED } from 'settings/taxes';

import { formatPrice } from 'services/formatter';
import { normalizeTax } from 'services/taxes';

interface ProgramPriceProps {
  fiscality: string | undefined;
  highlightClassName?: string;
  max?: number;
  min: number;
}

export function ProgramPrice({ fiscality, highlightClassName, min, max = 0 }: ProgramPriceProps) {
  const normalizedTax = normalizeTax(fiscality);
  const taxLabel =
    normalizedTax === TAX_TYPE_LMNP || normalizedTax === TAX_TYPE_LMNP_MANAGED
      ? `(${LABEL_HT})`
      : `(${LABEL_TTC})`;

  if ((min && !max) || min === max) {
    return (
      <div className="result-item__price">
        {LABEL_FROM}{' '}
        <span className={highlightClassName}>
          {formatPrice(min)} {taxLabel}
        </span>
      </div>
    );
  }

  if (min && max > 0) {
    return (
      <div className="result-item__price">
        {LABEL_PRICE_OF}{' '}
        <span className={highlightClassName}>
          {formatPrice(min)} {LABEL_TO} {formatPrice(max)}
        </span>{' '}
        {taxLabel}
      </div>
    );
  }

  return null;
}
