import React, { useContext, useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Dialog as MuiDialog } from '@material-ui/core';
import classnames from 'classnames';

import { LABEL_NEW_RESIDENCE, LABEL_PREVIEW } from 'settings/labels';
import { MEDIAS_TYPE_IMAGE } from 'settings/medias';
import { TAX_TYPE_LMNP } from 'settings/taxes';

import type { HomeProgram } from 'api/viOffresAPI/apiTypes/Program';

import { showQuarter } from 'services/date';
import { formatPrice } from 'services/formatter';
import {
  programIsControlledPrice,
  programIsFNO,
  programIsFurnishEligible,
  programIsNew,
  programIsPreview,
  programIsReducedVAT,
  programIsSenior,
  programIsStudent,
} from 'services/programs';
import { switchSinglePlural } from 'services/string';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';
import MediasVerticalSlider from 'commonUi/MediasVerticalSlider/MediasVerticalSlider';
import PastilleFiscality from 'commonUi/Pastille/PastilleFiscality';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './HomeProgramsModal.module.scss';

const LOGIN_URL = window.vinci.REACT_APP_LOGIN_URL;
const SIGNUP_URL = window.vinci.REACT_APP_SIGNUP_URL;

interface HomeProgramsModalProps {
  program: HomeProgram | undefined;
}

export default function HomeProgramsModal({ program }: HomeProgramsModalProps) {
  const { isResponsive } = useContext(ResponsiveContext);
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    if (isResponsive) {
      window.history.back();
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Open the modal when the given program changes
    if (program) {
      setIsOpen(true);
      if (isResponsive) {
        window.history.pushState({ homeProgramsOpen: true }, 'prout');
      }
    }
  }, [program]);

  useEffect(() => {
    function onPopState(ev: PopStateEvent) {
      setIsOpen(ev.state?.homeProgramsOpen === true);
    }

    if (isResponsive) {
      window.addEventListener('popstate', onPopState);
      return () => window.removeEventListener('popstate', onPopState);
    }

    return undefined;
  }, []);

  if (!program) {
    return null;
  }

  const isNew = programIsNew(program);
  const isPreview = programIsPreview(program);

  return (
    <MuiDialog
      classes={{ root: styles.root, paper: styles.paper }}
      open={isOpen}
      onClose={handleClose}
      maxWidth={false}
    >
      <button type="button" className={styles.close} onClick={handleClose}>
        <Icon icon="cross-bold" />
      </button>
      <div
        className={classnames(styles.info, {
          [styles.new]: isNew && !isPreview,
          [styles.preview]: isPreview,
        })}
      >
        {isNew && !isPreview && <div className={styles.new}>{LABEL_NEW_RESIDENCE}</div>}
        {isPreview && <div className={styles.preview}>{LABEL_PREVIEW}</div>}
        <div className={styles.title}>{program.nomCommercial}</div>
        <div className={styles.location}>
          {program.ville} ({program.codePostal.slice(0, 2)})
        </div>

        <div className={styles.pictos}>
          {program.fiscalite.map(tax => (
            <PastilleFiscality
              key={tax}
              className={styles.picto}
              tax={tax}
              ignore={TAX_TYPE_LMNP}
            />
          ))}
          {programIsReducedVAT(program) && (
            <SvgIcon className={styles.picto} iconId="icon-tva-5-5" />
          )}
          {programIsSenior(program) && <SvgIcon className={styles.picto} iconId="icon-ovelia" />}
          {programIsStudent(program) && <SvgIcon className={styles.picto} iconId="icon-student" />}
          {programIsFNO(program) && <SvgIcon className={styles.picto} iconId="icon-fno" />}
          {programIsControlledPrice(program) && (
            <SvgIcon className={styles.picto} iconId="icon-prix-maitrises" />
          )}
          {programIsFurnishEligible(program) && (
            <SvgIcon className={styles.picto} iconId="icon-furnish-eligible" />
          )}

          <span className={styles.lots}>
            {switchSinglePlural(Number(program.nbLots), 'lot disponible', 'lots disponibles')}
          </span>
        </div>

        <div className={styles.price}>
          De {formatPrice(program.prixHorsMobilier.from, '€', true)} à{' '}
          {formatPrice(program.prixHorsMobilier.to, '€', true)}&nbsp;HT*
        </div>

        <dl className={styles.list}>
          <div className={styles.item}>
            <dt>Type de résidence</dt>
            <dd>{program.type}</dd>
          </div>
          <div className={styles.item}>
            <dt>Date d&apos;actabilité</dt>
            <dd>{showQuarter(program.actabilite, 'Non communiquée')}</dd>
          </div>
          <div className={styles.item}>
            <dt>Date de livraison</dt>
            <dd>{showQuarter(program.livraisonPrevisionnelle, 'Non communiquée')}</dd>
          </div>
          {!!program.typesDeLots?.length && (
            <div className={styles.item}>
              <dt>Typologie des lots disponibles</dt>
              <dd>{program.typesDeLots.sort().join(', ')}</dd>
            </div>
          )}
        </dl>

        <div className={styles.notice}>* Prix hors mobilier</div>
      </div>

      <div className={styles.description}>
        {program.texte && <FieldRte className={styles.text} html={program.texte} />}

        {!!program.photos.length && isResponsive && (
          <Slider
            className={styles.medias}
            arrows={false}
            dots={false}
            infinite={false}
            slidesToScroll={1}
            variableWidth
          >
            {program.photos.map(photo => (
              <div className={styles.media}>
                <img key={photo} src={photo} alt="" />
              </div>
            ))}
          </Slider>
        )}
        {!!program.photos.length && !isResponsive && (
          <MediasVerticalSlider
            classes={{
              root: styles.medias,
              slider: styles.slider,
              slide: styles.slide,
              activeMedia: styles.selected,
              activeSlide: styles.active,
            }}
            medias={program.photos.map(photo => ({
              url: photo,
              properties: { type: MEDIAS_TYPE_IMAGE },
            }))}
          />
        )}

        <div className={styles.login}>
          <div className={styles.text}>Pour en savoir plus&nbsp;:</div>
          <div className={styles.buttons}>
            <a className={classnames(styles.link, 'button')} href={LOGIN_URL}>
              <span className="text">Je me connecte</span>
            </a>
            <a className={classnames(styles.link, 'button', 'borderBlue')} href={SIGNUP_URL}>
              <span className="text">Je deviens partenaire</span>
            </a>
          </div>
        </div>
      </div>
    </MuiDialog>
  );
}
