import { useContext } from 'react';

import {
  LABEL_ACTABILITY,
  LABEL_AND_MORE,
  LABEL_DELIVERY,
  LABEL_KIND,
  LABEL_PROFITABILITY,
  LABEL_RANGE_SHORT,
} from 'settings/labels';
import { OTHER_OPTIONS, SEARCH_MAX_PRIZE } from 'settings/search';
import { TOKEN_MAX, TOKEN_MIN } from 'settings/token';

import type { SearchType } from 'modules/HomePage/hooks/useSearch';

import { locationToObject } from 'services/filters';
import { budgetValueFormatter, replaceTokens } from 'services/formatter';
import { decodeEntity } from 'services/string';
import { normalizeTax, taxLabelMapping } from 'services/taxes';

import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

function filterArrayToString(
  filter: string[] | undefined,
  mapping: Map<string, any>,
  separator: string
) {
  return filter
    ?.map(value => mapping.get(value))
    .filter(x => x)
    .join(separator);
}

function filterRangeToString(rangeMin: number, rangeMax: number, unit: string, unitMax?: string) {
  return replaceTokens(LABEL_RANGE_SHORT, {
    [TOKEN_MIN]: `${rangeMin}${rangeMin !== 0 ? unit : ''}`,
    [TOKEN_MAX]: `${rangeMax}${unitMax || unit}`,
  });
}

function addPrefix(prefix: string, value: string | undefined) {
  return value ? `${prefix}: ${value}` : value;
}

export default function useSearchFiltersAsString(filters: Partial<SearchType>, separator = ', ') {
  const {
    annexes: annexOptions,
    kinds: kindOptions,
    taxes: taxesOptions,
    years: yearOptions,
  } = useContext(TaxonomiesContext);

  if (
    !filters ||
    Object.keys(filters).length === 0 ||
    !yearOptions ||
    !taxesOptions ||
    !kindOptions ||
    !annexOptions
  ) {
    return undefined;
  }

  const result = [
    'locations',
    'budget',
    'delivery',
    'taxes',
    'kinds',
    'annexes',
    'surface',
    'actability',
    'profitability',
    'others',
  ]
    .map(key => {
      switch (key) {
        case 'actability':
          return addPrefix(
            LABEL_ACTABILITY,
            filterArrayToString(
              filters.actability,
              new Map(yearOptions.map(({ value, label }) => [value, label])),
              separator
            )
          );

        case 'annexes':
          return filterArrayToString(
            filters.annexes,
            new Map(annexOptions.map(({ id, nom }) => [id, nom])),
            separator
          );

        case 'budget':
          return (
            filters.budgetMin &&
            filters.budgetMax &&
            filterRangeToString(
              budgetValueFormatter(parseInt(filters.budgetMin, 10)),
              budgetValueFormatter(parseInt(filters.budgetMax, 10)),
              'K€',
              parseInt(filters.budgetMax, 10) === SEARCH_MAX_PRIZE ? `K€ ${LABEL_AND_MORE}` : 'K€'
            )
          );

        case 'delivery':
          return addPrefix(
            LABEL_DELIVERY,
            filterArrayToString(
              filters.delivery,
              new Map(yearOptions.map(({ label, value }) => [value, label])),
              separator
            )
          );

        case 'kinds':
          return addPrefix(
            LABEL_KIND,
            filterArrayToString(
              filters.kinds,
              new Map(kindOptions.map(({ id, nom }) => [id, nom])),
              separator
            )
          );

        case 'locations': {
          return locationToObject(filters.locations)
            ?.map(l => l.value)
            .join(', ');
        }

        case 'others':
          return filterArrayToString(
            filters.others,
            new Map(OTHER_OPTIONS.map(({ label, value }) => [value, label])),
            separator
          );

        case 'profitability':
          return addPrefix(
            LABEL_PROFITABILITY,
            filters.profitabilityMin &&
              filters.profitabilityMax &&
              filterRangeToString(
                parseInt(filters.profitabilityMin, 10),
                parseInt(filters.profitabilityMax, 10),
                '%',
                '%'
              )
          );

        case 'surface':
          return (
            filters.surfaceMin &&
            filters.surfaceMax &&
            filterRangeToString(
              parseInt(filters.surfaceMin, 10),
              parseInt(filters.surfaceMax, 10),
              'm²'
            )
          );

        case 'taxes': {
          return filterArrayToString(
            filters.taxes,
            new Map(
              taxesOptions.reduce<[string, NonNullable<ReturnType<typeof taxLabelMapping.get>>][]>(
                (array, { id, nom }) => {
                  const normalized = normalizeTax(nom);
                  const label = normalized ? taxLabelMapping.get(normalized) : undefined;
                  return label ? [...array, [id, label]] : array;
                },
                []
              )
            ),
            separator
          );
        }

        default:
          return filters[key];
      }
    })
    .filter(x => x)
    .join(separator);

  return decodeEntity(result);
}
