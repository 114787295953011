export const LOTS_PER_PAGE_TABLET = 10;
export const LOTS_PER_PAGE_MOBILE = 2;

export const LOT_ARRAY_LENGTH = 27;

export const LOT_CELL_TYPE_ACTION = 'action';
export const LOT_CELL_TYPE_DATE = 'date';
export const LOT_CELL_TYPE_KIND_WITH_PLAN = 'kind_plan';
export const LOT_CELL_TYPE_LOT = 'lot';
export const LOT_CELL_TYPE_PERCENTAGE = 'percentage';
export const LOT_CELL_TYPE_PLAN = 'plan';
export const LOT_CELL_TYPE_PRICE = 'price';
export const LOT_CELL_TYPE_PROGRAM = 'program';
export const LOT_CELL_TYPE_PROMO = 'promo';
export const LOT_CELL_TYPE_RENT = 'rent';
export const LOT_CELL_TYPE_STATUS = 'status';
export const LOT_CELL_TYPE_SURFACE = 'surface';
export const LOT_CELL_TYPE_SURFACE_ANNEX = 'surfaceAnnex';
export const LOT_CELL_TYPE_TAX = 'tax';
export const LOT_CELL_TYPE_CHECKBOX = 'checkbox';
export const LOT_CELL_TYPE_VALUE = 'value';

export const LOT_CELL_ID_CUSTOM_PRICE_1 = 'customPrice1';
export const LOT_CELL_ID_CUSTOM_PRICE_2 = 'customPrice2';
export const LOT_CELL_ID_PRICE_WITH_TAX = 'priceIncludingTax';
export const LOT_CELL_ID_PRICE_REDUCED_VAT = 'priceReducedVat';
export const LOT_CELL_ID_DELIVERY = 'delivery';
export const LOT_CELL_ID_TAX = 'tax';
export const LOT_CELL_ID_PROGRAM = 'program';
export const LOT_CELL_ID_NUMBER = 'number';
export const LOT_CELL_ID_PRICE1 = 'price1';
export const LOT_CELL_ID_PRICE2 = 'price2';
export const LOT_CELL_ID_PROFITABILITY = 'profitability';
export const LOT_CELL_ID_PROFITABILITY_CUSTOM = 'customProfitability';
export const LOT_CELL_ID_STATUS = 'status';
export const LOT_CELL_ID_PLAN = 'plan';
export const LOT_CELL_ID_KIND = 'kind';
export const LOT_CELL_ID_FLOOR = 'floor';
export const LOT_CELL_ID_PARKING = 'hasParking';
export const LOT_CELL_ID_SURFACE = 'surface';
export const LOT_CELL_ID_SURFACE_ANNEX = 'surfaceAnnex';
export const LOT_CELL_ID_EXPOSURE = 'exposure';
export const LOT_CELL_ID_CUSTOM_RENT = 'customRent';
export const LOT_MOBILE_HEADINGS = [
  LOT_CELL_ID_STATUS,
  LOT_CELL_ID_TAX,
  LOT_CELL_ID_PROGRAM,
  LOT_CELL_ID_NUMBER,
  LOT_CELL_TYPE_CHECKBOX,
];
export const LOT_TABLETTE_HEADINGS = [
  LOT_CELL_ID_NUMBER,
  LOT_CELL_ID_STATUS,
  LOT_CELL_ID_TAX,
  LOT_CELL_ID_PROGRAM,
  LOT_CELL_TYPE_CHECKBOX,
];

// List of different lot status
export const LOT_STATUS_ACTED = 'Acté';
export const LOT_STATUS_BOOKED = 'Réservé';
export const LOT_STATUS_DELIVERED = 'Livré';
export const LOT_STATUS_FREE = 'Libre';
export const LOT_STATUS_OPTIONED = 'Optionné';
export const LOT_STATUS_PRE_BOOKED = 'Pré-réservé';
export const LOT_STATUS_UNAVAILABLE = 'Non offert';

// INDEXES OF EACH FIELD IN AN UNFORMATTED LOT JSON ARRAY
export const LOT_JSON_NID = 0;
export const LOT_JSON_PROGRAM_ID = 1;
export const LOT_JSON_TAX = 2;
export const LOT_JSON_TYPE = 3;
export const LOT_JSON_NUMBER = 4;
export const LOT_JSON_KIND_TID = 5;
export const LOT_JSON_KIND = 6;
export const LOT_JSON_FLOOR = 7;
export const LOT_JSON_EXPOSURE = 8;
export const LOT_JSON_SURFACE = 9;
export const LOT_JSON_SURFACE_ANNEX = 10;
export const LOT_JSON_HAS_PARKING = 11;
export const LOT_JSON_PRICE_REDUCED_VAT = 12;
export const LOT_JSON_PRICE_INCLUDING_TAX = 13;
/** @deprecated */
export const LOT_JSON_RENT_HT = 14; // Should be unused because it was replaced by RENT_TTC
export const LOT_JSON_RENT_TTC = 15;
export const LOT_JSON_PROFITABILITY = 16;
export const LOT_JSON_DELIVERY = 17;
export const LOT_JSON_STATUS = 18;
export const LOT_JSON_EXPIRATION_DATE = 19;
export const LOT_JSON_CUSTOM_PRICE1 = 20;
export const LOT_JSON_CUSTOM_PRICE2 = 21;
export const LOT_JSON_CUSTOM_RENT = 22;
export const LOT_JSON_CUSTOM_PROFITABILITY = 23;
export const LOT_JSON_ATTRIBUTION = 24;
export const LOT_JSON_ANNEXES = 25;
export const LOT_JSON_OTHERS = 26;
export const LOT_JSON_HAS_PLAN = 27;
export const LOT_JSON_PROGRAM_REF = 28;
export const LOT_JSON_KITCHEN_TYPE = 29;
export const LOT_JSON_KITCHEN_PRICE = 30;
export const LOT_JSON_SALES_INTERN = 31;
export const LOT_JSON_SALES_VI2P = 32;
export const LOT_JSON_PROMOTIONS = 33;
export const LOT_JSON_RENT_FURNISHED = 34;

export const LOT_JSON_LENGTH = 35;

export const HEADINGS_TYPE_DEFAULT = 'default';
export const HEADINGS_TYPE_DEFAULT_REDUCED_PANEL = 'defaultReducedPanel';
export const HEADINGS_TYPE_DEMEMBREMENT = 'demembrement';
export const HEADINGS_TYPE_DEMEMBREMENT_REDUCED_PANEL = 'demembrementReducedPanel';
export const HEADINGS_TYPE_HORS_DISPO = 'horsDispositif';
export const HEADINGS_TYPE_HORS_DISPO_REDUCED_PANEL = 'horsDispositifReducedPanel';
export const HEADINGS_TYPE_LMNP = 'lmnp';
export const HEADINGS_TYPE_LMNP_REDUCED_PANEL = 'lmnpReducedPanel';
export const HEADINGS_TYPE_REDUCED_PANEL = 'reducedPanel';

export const LOT_GRID_NB_LINES = 10;

export const LOTS_IDS_PARAM_LENGTH = 7500;

export const LOT_DEFAULT_SECURITY_DEPOSIT = '1 600 €';

export const LOT_PROMO_COL_WIDTH = 30;
