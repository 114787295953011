export function ellipsisText(text, maxLength = 60) {
  const actualText = text === undefined || text === null ? '' : text;
  if (actualText.length > maxLength) {
    return `${actualText.substring(0, maxLength)}...`;
  }
  return actualText;
}

export function switchSinglePlural(value, single, plural) {
  return value > 1 ? `${value} ${plural}` : `${value} ${single}`;
}

export function switchSinglePluralWithReplacement(value, single, plural) {
  return value > 1 ? plural.replace('%n', value) : single.replace('%n', value);
}

export function decodeEntity(inputStr) {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = inputStr;
  return textarea.value;
}
