import React from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';

import { showQuarter } from 'services/date';
import { normalizeTax } from 'services/taxes';
import { PARKING_TYPE } from 'settings/app';
import {
  LABEL_ACTABILITY_DATE,
  LABEL_CHAUFFAGE_TYPE,
  LABEL_GENERAL_INFORMATIONS,
  LABEL_MANAGER,
  LABEL_NATURE,
  LABEL_RESIDENCE_TYPE,
  LABEL_SERVICES,
} from 'settings/labels';
import { PROGRAM_HOUSING_TYPE_MAPPING } from 'settings/programs';
import { TAX_TYPE_DEMEMBREMENT, TAX_TYPE_LMNP, TAX_TYPE_LMNP_MANAGED } from 'settings/taxes';

import type { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { TaxTaxonomyTerm } from 'api/viOffresAPI/apiTypes/Taxonomies';

import { lotHasKitchen } from 'services/lots';
import { lotIsFNO } from 'services';

import RowDetailsList from 'commonUi/RowDetailsList/RowDetailsList';
import KitchenEquippedInfosBlock from 'commonUi/KitchenEquippedInfosBlock/KitchenEquippedInfosBlock';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import AdbInfosBlock from 'commonUi/AdbInfosBlock/AdbInfosBlock';

import styles from './LotDetailsInformationsGeneral.module.scss';

interface LotDetailsInformationsGeneralProps {
  classes?: {
    root?: string;
    title?: string;
  };
  fiscality?: TaxTaxonomyTerm;
  lot: LotTypeV2;
  program: ProgramTypeV2;
}

export default function LotDetailsInformationsGeneral({
  classes = {},
  fiscality,
  lot,
  program,
}: LotDetailsInformationsGeneralProps) {
  const taxType = normalizeTax(fiscality?.nom);
  const isLMNP = taxType === TAX_TYPE_LMNP || taxType === TAX_TYPE_LMNP_MANAGED;
  const isDemembrement = taxType === TAX_TYPE_DEMEMBREMENT;

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    arrows: true,
    slidesToScroll: 1,
    variableWidth: true,
    initialSlide: 0,
  };
  const housingType = program?.type && PROGRAM_HOUSING_TYPE_MAPPING.get(program.type);

  return (
    <div className={classes?.root}>
      <div className={classes?.title}>{LABEL_GENERAL_INFORMATIONS}</div>
      <RowDetailsList
        items={{
          [LABEL_ACTABILITY_DATE]: {
            label: LABEL_ACTABILITY_DATE,
            value: showQuarter(program?.dates.actabilite),
          },
          [LABEL_RESIDENCE_TYPE]: {
            label: LABEL_RESIDENCE_TYPE,
            value: housingType || program?.type,
          },
          [LABEL_CHAUFFAGE_TYPE]: {
            label: LABEL_CHAUFFAGE_TYPE,
            value: lot?.typeChauffage,
          },
          ...(isLMNP || isDemembrement
            ? {
                [LABEL_MANAGER]: {
                  label: LABEL_MANAGER,
                  value:
                    program?.residencesGerees?.nomGestionnaire &&
                    program?.residencesGerees?.nomGestionnaire !== '.'
                      ? program.residencesGerees.nomGestionnaire
                      : undefined,
                },
              }
            : {}),
          ...(lot.natureSimplifiee === PARKING_TYPE
            ? {
                [LABEL_NATURE]: {
                  label: LABEL_NATURE,
                  value: lot.nature,
                },
              }
            : {}),
        }}
      />
      <div className={styles.services}>
        {(lotHasKitchen(lot) || lot?.packs?.eligibleAdb) && (
          <div className={styles.servicesTitle}>{LABEL_SERVICES}</div>
        )}
        <div className={classNames({ [styles.servicesContainer]: !lotHasKitchen(lot) })}>
          <Slider sliderSettings={sliderSettings}>
            {lotHasKitchen(lot) && (
              <div className={styles.servicesItem}>
                <KitchenEquippedInfosBlock lot={lot} />
              </div>
            )}
            {lot?.packs?.eligibleAdb && (
              <div className={styles.servicesItem}>
                <AdbInfosBlock program={program} />
              </div>
            )}
            {lotIsFNO(lot) && lotHasKitchen(lot) && (
              <div
                className={classNames(styles.servicesItem, {
                  [styles.servicesItemSlider]: lotHasKitchen(lot),
                })}
              >
                <SvgIcon className={styles.picto} iconId="icon-fno" />
              </div>
            )}
          </Slider>
          {lotIsFNO(lot) && !lotHasKitchen(lot) && (
            <div className={styles.servicesItem}>
              <SvgIcon className={styles.picto} iconId="icon-fno" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
