import React from 'react';

import type { HomeProgram } from 'api/viOffresAPI/apiTypes/Program';

import { programIsSenior, programIsStudent } from 'services/programs';
import { normalizeTax } from 'services/taxes';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import { SEARCH_CRITERIA_VAT_REDUCED } from 'settings/search';
import { TAX_TYPE_DEMEMBREMENT, TAX_TYPE_MALRAUX } from 'settings/taxes';

interface HomeProgramPictoProps {
  className?: string;
  program: HomeProgram;
}

export default function HomeProgramPicto({ className, program }: HomeProgramPictoProps) {
  const taxes = program.fiscalite.map(tax => normalizeTax(tax));

  if (program.typeDeSuggestion === 'ovelia' && programIsSenior(program)) {
    return <SvgIcon className={className} iconId="icon-ovelia" />;
  }

  if (program.typeDeSuggestion === 'student_factory' && programIsStudent(program)) {
    return <SvgIcon className={className} iconId="icon-student" />;
  }

  if (program.typeDeSuggestion === 'np' && taxes.includes(TAX_TYPE_DEMEMBREMENT)) {
    return <SvgIcon className={className} iconId="icon-fiscality-nue-propriete" />;
  }

  if (program.typeDeSuggestion === 'malraux' && taxes.includes(TAX_TYPE_MALRAUX)) {
    return <SvgIcon className={className} iconId="icon-fiscality-malraux" />;
  }

  if (
    program.typeDeSuggestion === 'tva_reduite' &&
    program.autres.includes(SEARCH_CRITERIA_VAT_REDUCED)
  ) {
    return <SvgIcon className={className} iconId="icon-tva-5-5" />;
  }

  return null;
}
