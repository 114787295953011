import React from 'react';
import classnames from 'classnames';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import styles from './HeaderGuest.module.scss';

const LOGIN_URL = window.vinci.REACT_APP_LOGIN_URL;
const SIGNUP_URL = window.vinci.REACT_APP_SIGNUP_URL;

interface HeaderGuestProps {
  onScrollerClick?: () => void;
  visible?: boolean;
}

export default function HeaderGuest({ onScrollerClick, visible = false }: HeaderGuestProps) {
  return (
    <header className={classnames(styles.root, { [styles.visible]: visible })} id="guest-header">
      <a className={classnames(styles.logo, styles.vip)} href="/">
        <picture>
          <source srcSet="/images/logo_vip_color.png" media="(max-width: 991px)" />
          <img
            src="/images/logo_vi3p_color.png"
            alt="Logo Vinci Patrimoine"
            width={976}
            height={201}
          />
        </picture>
      </a>
      <a className={classnames(styles.logo, styles.vi)} href="/">
        <img src="/images/new_images/logo_vinci.svg" alt="Logo vinci" width="120" height="44" />
      </a>

      <div className={styles.buttons}>
        {onScrollerClick && (
          <>
            <button
              type="button"
              className={classnames(styles.link, styles.contact, 'button', 'soft')}
              onClick={onScrollerClick}
            >
              <Icon className="icon" icon="contact" />
              Contactez-nous
            </button>
            <span className={styles.separator} />
          </>
        )}
        <a className={classnames(styles.link, 'button', 'reverse')} href={SIGNUP_URL}>
          Je deviens partenaire
        </a>
        <a className={classnames(styles.link, 'button')} href={LOGIN_URL}>
          Je me connecte
        </a>
      </div>
    </header>
  );
}
