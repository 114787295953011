import React from 'react';
import type { MouseEventHandler } from 'react';
import classnames from 'classnames';

import { LABEL_HT, LABEL_NEW_RESIDENCE, LABEL_PREVIEW, LABEL_TTC } from 'settings/labels';
import { TAX_TYPE_LMNP, TAX_TYPE_LMNP_MANAGED } from 'settings/taxes';

import type { HomeProgram as HomeProgramType } from 'api/viOffresAPI/apiTypes/Program';

import { showQuarter } from 'services/date';
import { formatPrice } from 'services/formatter';
import { programIsNew, programIsPreview } from 'services/programs';

import HomeProgramPicto from './HomeProgramPicto';

import styles from './HomeProgram.module.scss';

interface HomeProgramProps {
  className?: string;
  onClick?: MouseEventHandler;
  program: HomeProgramType;
}

export default function HomeProgram({ className, onClick, program }: HomeProgramProps) {
  return (
    <button type="button" className={classnames(className, styles.root)} onClick={onClick}>
      <div className={styles.info}>
        <div className={styles.location}>
          {program.ville}&nbsp;{program.codePostal.slice(0, 2)}
        </div>
        <div className={styles.name}>{program.nomCommercial}</div>
        <div className={styles.price}>
          {program.prixHorsMobilier.from === null ? (
            <>Prix non communiqué</>
          ) : (
            <>
              À partir de{' '}
              <span className={styles.number}>
                {formatPrice(program.prixHorsMobilier.from, '€', true)}
              </span>
              &nbsp;(
              {program.fiscalite.some(tax => [TAX_TYPE_LMNP, TAX_TYPE_LMNP_MANAGED].includes(tax))
                ? LABEL_HT
                : LABEL_TTC}
              )
            </>
          )}
        </div>
        <div className={styles.delivery}>
          Livraison{' '}
          {program.livraisonPrevisionnelle ? (
            showQuarter(program.livraisonPrevisionnelle)
          ) : (
            <>non communiquée</>
          )}
        </div>
      </div>

      <div className={styles.top}>
        {programIsNew(program) && <div className={styles.new}>{LABEL_NEW_RESIDENCE}</div>}
        {programIsPreview(program) && <div className={styles.preview}>{LABEL_PREVIEW}</div>}
        <HomeProgramPicto className={styles.picto} program={program} />
        <img className={styles.image} src={program.photos[0]} alt="" />
      </div>
    </button>
  );
}
