import React, { useState } from 'react';
import type { ContextType } from 'react';
import classnames from 'classnames';

import type SettingsContext from 'modules/App/Contexts/SettingsContext';

import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';

import styles from './FooterColumn.module.scss';

interface FooterColumnProps {
  classes?: Partial<
    Record<'btn' | 'container' | 'content' | 'picto' | 'root' | 'text' | 'title', string>
  >;
  column: NonNullable<ContextType<typeof SettingsContext>['settings']['footer']>['blocs'][number];
}

export default function FooterColumn({ classes = {}, column }: FooterColumnProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={classnames(classes.root, styles.root, {
        [styles.open]: isOpen,
        [styles.foldable]: column.collapse_mobile,
      })}
    >
      {column.collapse_mobile && (
        <button
          type="button"
          className={classnames(classes.btn, styles.btn)}
          onClick={() => setIsOpen(prev => !prev)}
        >
          {column.collapse_mobile}
        </button>
      )}

      <div className={classnames(classes.container, styles.container)}>
        {column.image && (
          <img
            className={classnames(classes.picto, styles.picto)}
            src={column.image}
            alt={`Picto ${column.titre}`}
          />
        )}

        <div className={classnames(classes.content, styles.content)}>
          {column.titre && (
            <FieldRte
              html={column.titre}
              className={classnames(classes.title, styles.rte, styles.title)}
            />
          )}
          {column.contenu && (
            <FieldRte html={column.contenu} className={classnames(classes.text, styles.rte)} />
          )}
        </div>
      </div>
    </div>
  );
}
