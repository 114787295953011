import React, { useContext, useState } from 'react';
import type { ReactNode } from 'react';
import classnames from 'classnames';
import { CircularProgress } from '@material-ui/core';
import { forceVisible } from 'react-lazyload';

import type { ActualityType } from 'api/vi3pAPI/apiTypes/ActualityType';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';

import { useSWRVi3p } from 'api/vi3pAPI/useSWRVi3p';

import SlickSlider from 'commonUi/SlickSlider/SlickSlider';

import NewsItem from './NewsItem';

import styles from './News.module.scss';

interface NewsProps {
  className?: string;
  title?: ReactNode;
  visibleItems?: number;
}

export default function News({ className, title, visibleItems = 5 }: NewsProps) {
  const { isResponsive } = useContext(ResponsiveContext);
  const { data = [], isLoading } = useSWRVi3p<ActualityType[]>({ url: `actualites` });
  const [isFolded, setIsFolded] = useState(true);

  if (isLoading) {
    return (
      <div className={classnames(styles.root, styles.loading)}>
        <CircularProgress classes={{ root: styles.loader }} variant="indeterminate" size={24} />
      </div>
    );
  }

  if (!data.length) {
    return null;
  }

  const renderNewsItems = (items: ActualityType[]) =>
    items.map((item, index) => (
      <NewsItem
        key={item.nid}
        className={styles.item}
        href={`/actualites/${item.nid}`}
        image={!isResponsive && index === 0 ? item.photo?.['1200x300'] : item.photo?.['270x200']}
        isHidden={!isResponsive && index >= visibleItems && isFolded}
        title={item.titre}
      />
    ));

  return (
    <div className={classnames(className, styles.root)}>
      {title && <h2 className={styles.title}>{title}</h2>}

      {isResponsive && (
        <SlickSlider
          className={styles.list}
          dots
          variableWidth
          arrows={false}
          dotsClass={classnames(styles.dots, 'slider-dots')}
          infinite={false}
        >
          {renderNewsItems(data)}
        </SlickSlider>
      )}

      {!isResponsive && (
        <>
          <div className={styles.list}>{renderNewsItems(data)}</div>
          {data.length > visibleItems && isFolded && (
            <button
              type="button"
              className={classnames(styles.unfold, 'button', 'hoverBorderBlue')}
              onClick={() => {
                setIsFolded(false);
                forceVisible();
              }}
            >
              Voir Plus
            </button>
          )}
        </>
      )}
    </div>
  );
}
