import React from 'react';
import classnames from 'classnames';

import { LABEL_HT, LABEL_TTC } from 'settings/labels';
import {
  TAX_TYPE_DEMEMBREMENT,
  TAX_TYPE_LMNP,
  TAX_TYPE_LMNP_MANAGED,
  TAX_TYPE_MALRAUX,
  TAX_TYPE_NONE,
} from 'settings/taxes';

import type { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';

import { formatPrice } from 'services/formatter';
import { normalizeTax } from 'services/taxes';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './LotPrice.module.scss';

interface LotPriceProps {
  displayPanel?: boolean;
  lot: LotTypeV2 | undefined;
}

export function LotPrice({ displayPanel = false, lot }: LotPriceProps) {
  if (!lot) {
    return null;
  }

  switch (normalizeTax(lot.fiscalites[0])) {
    case TAX_TYPE_DEMEMBREMENT:
      return (
        <div className={classnames(styles.root, { [styles.displayPanel]: displayPanel })}>
          <div className={styles.priceContainer}>
            <SvgIcon className={styles.svgIcon} iconId="icon-home-euro" />
            <span className={styles.highlight}>
              <span>
                {formatPrice(lot.prix.TVANormale.prixTTC)} {LABEL_TTC}
              </span>
            </span>
          </div>
        </div>
      );

    case TAX_TYPE_LMNP:
    case TAX_TYPE_LMNP_MANAGED:
      return (
        <div className={classnames(styles.root, { [styles.displayPanel]: displayPanel })}>
          <div className={styles.priceContainer}>
            <SvgIcon className={styles.svgIcon} iconId="icon-home-euro" />
            <span className={styles.highlight}>
              <span>
                {formatPrice(lot.prix.prixHTHorsMobilier)} {LABEL_HT}
              </span>
            </span>
          </div>
        </div>
      );

    case TAX_TYPE_MALRAUX:
      return (
        <div className={classnames(styles.root, { [styles.displayPanel]: displayPanel })}>
          <div className={styles.priceContainer}>
            <SvgIcon className={styles.svgIcon} iconId="icon-home-euro" />
            <span className={styles.highlight}>
              <span>
                {formatPrice(lot.prix.prixHTHorsMobilier)} {LABEL_TTC}
              </span>
            </span>
          </div>
        </div>
      );

    case TAX_TYPE_NONE:
      return (
        <div
          className={classnames(styles.root, {
            [styles.displayPanel]: displayPanel,
            [styles.double]: lot.prix.TVAalternative?.prixTTC,
          })}
        >
          <div className={styles.priceContainer}>
            <SvgIcon className={styles.svgIcon} iconId="icon-home-euro" />
            <span className={styles.highlight}>
              <span>
                {formatPrice(lot.prix.TVANormale.prixTTC)} {LABEL_TTC}
              </span>
            </span>
          </div>
          {!!lot?.prix.TVAalternative?.prixTTC && (
            <div className={styles.priceContainer}>
              <SvgIcon className={styles.svgIcon} iconId="icon-home-euro" />
              <span className={styles.highlight}>
                <span>
                  {formatPrice(lot.prix.TVAalternative.prixTTC)} {LABEL_TTC}{' '}
                </span>
                <span className={styles.tax}>TVA {lot.prix.TVAalternative.tauxTva}%</span>
              </span>
            </div>
          )}
        </div>
      );

    default:
      return null;
  }
}
