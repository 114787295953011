import React, { forwardRef, useContext, useMemo } from 'react';
import Slider from 'react-slick';
import classnames from 'classnames';

import SettingsContext from 'modules/App/Contexts/SettingsContext';

import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';

import logoVI3P from 'images/logo_vi3p_white.svg';

import styles from './HomeBanner.module.scss';

const LOGIN_URL = window.vinci.REACT_APP_LOGIN_URL;
const SIGNUP_URL = window.vinci.REACT_APP_SIGNUP_URL;

interface HomeBannerProps {
  className?: string;
}

export default forwardRef<HTMLDivElement, HomeBannerProps>(function HomeBanner({ className }, ref) {
  const { settings } = useContext(SettingsContext);

  const { title, text, images, speed = 3 } = useMemo(
    () => ({
      title: settings.home.titre,
      text: settings.home.sous_titre,
      images: settings.home.image,
      speed: settings.home.speed,
    }),
    [settings.home]
  );

  return (
    <div ref={ref} className={classnames(className, styles.root)}>
      <div className={styles.content}>
        <div className={styles.logos}>
          <img className={styles.vip} src={logoVI3P} alt="Logo Vinci Immobilier Patrimoine" />
          <img
            className={styles.vi}
            src="/images/new_images/logo_vinci_white.svg"
            alt="Logo Vinci Immobilier"
          />
        </div>
        {title && <FieldRte className={styles.title} html={title} />}
        {text && <FieldRte className={styles.text} html={text} />}

        <div className={styles.buttons}>
          <a className={classnames(styles.button, 'button', 'hoverTransparent')} href={LOGIN_URL}>
            Je me connecte
          </a>
          <a className={classnames(styles.button, 'button', 'transparent')} href={SIGNUP_URL}>
            Je deviens partenaire
          </a>
        </div>
      </div>

      {images.length > 0 && (
        <div className={styles.images}>
          {images.length === 1 ? (
            <div className={styles.container}>
              <img className={styles.image} src={images[0]} alt="" />
            </div>
          ) : (
            <Slider
              dots
              autoplay
              pauseOnFocus
              pauseOnHover
              arrows={false}
              autoplaySpeed={speed * 1000}
              className={classnames(styles.container, styles.slider)}
              dotsClass={classnames(styles.dots, 'slider-dots', 'white')}
            >
              {images.map(image => (
                <img key={image} className={styles.image} src={image} alt="" />
              ))}
            </Slider>
          )}
        </div>
      )}
    </div>
  );
});
