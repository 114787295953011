import React, { useContext, useMemo } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { LABEL_NEW_RESIDENCE, LABEL_NO_LOT } from 'settings/labels';
import { TAX_TYPE_LMNP, TAX_TYPE_MALRAUX } from 'settings/taxes';

import type { ProgramListType, ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import { isProgramListType, isProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import {
  programHasKitchen,
  programIsControlledPrice,
  programIsFNO,
  programIsFurnishEligible,
  programIsHonoBoost,
  programIsNew,
} from 'services/programs';

import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import KitchenIcon from 'commonUi/KitchenIcon/KitchenIcon';
import PastilleFiscality from 'commonUi/Pastille/PastilleFiscality';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import { ProgramPrice } from 'modules/HomePage/Components/ProgramPrice/ProgramPrice';

import styles from './ProgramCard.module.scss';

function getProgramPriceRange(program: ProgramTypeV2) {
  const rangeIsUsable = (range: string) => range.split('-').every(Boolean);
  let range = program.informationsFiscalesEtFinancieres.prixTVANormal;
  if (program.informationsFiscalesEtFinancieres.fiscalites.includes(TAX_TYPE_MALRAUX)) {
    if (rangeIsUsable(program.informationsFiscalesEtFinancieres.prixHorsMobilier)) {
      range = program.informationsFiscalesEtFinancieres.prixHorsMobilier;
    } else {
      range = program.informationsFiscalesEtFinancieres.prixTVAReduite;
    }
  }
  return range.split('-').map(Number);
}

export interface ProgramCardType {
  // ProgramCard can be called from the Search context or from other places so it needs to be able to handle both types of Programs
  program?: ProgramListType | ProgramTypeV2;
}

export function ProgramCard({ program }: ProgramCardType) {
  const { taxesById } = useContext(TaxonomiesContext);
  const {
    budgetMax,
    budgetMin,
    city,
    imageSrc,
    lotNumber,
    postalCode,
    programName,
    programRef,
    fiscality,
    typology,
  } = useMemo(() => {
    if (!program) {
      return {};
    }
    if (isProgramTypeV2(program)) {
      const [budgetMin, budgetMax] = getProgramPriceRange(program);
      let typology: string[] = [];
      try {
        typology = Object.keys(JSON.parse(program.repartitionsLots));
      } catch (e) {
        // Do nothing
      }
      return {
        budgetMax,
        budgetMin,
        city: program.localisation.ville,
        imageSrc: program.perspectives.panorama,
        lotNumber: program.nbLotsDisponibles,
        postalCode: program.localisation.codePostal,
        programName: program.nomCommercial,
        programRef: program.referenceProgramme,
        fiscality: program.informationsFiscalesEtFinancieres.fiscalites[0],
        typology,
      };
    }
    if (isProgramListType(program)) {
      return {
        budgetMax: program.budget.max,
        budgetMin: program.budget.min,
        city: program.city,
        imageSrc: program.image,
        lotNumber: program.lotNumber,
        postalCode: program.postalCode,
        programName: program.name,
        programRef: program.ref,
        fiscality: taxesById?.[program.taxes[0]],
        typology: program.kindsDisplay,
      };
    }
    const exhaustivenessCheck: never = program;
    return exhaustivenessCheck;
  }, [program, taxesById]);

  if (!program) {
    return null;
  }

  const programUrl = `/programme/page/${programRef}/prix`;

  return (
    <div className={styles.root}>
      <div className={styles.image}>
        <ReactRouterLink className={styles.imgContainer} to={programUrl} target="_blank">
          <img
            className={styles.img}
            src={typeof imageSrc === 'string' ? imageSrc : imageSrc?.[0]}
            alt={programName}
          />
        </ReactRouterLink>
        {programIsNew(program) && <div className={styles.new}>{LABEL_NEW_RESIDENCE}</div>}
      </div>
      <div className={styles.infos}>
        <ReactRouterLink className={styles.program} to={programUrl} target="_blank">
          <div className={styles.name}>{programName}</div>
          <div className={styles.location}>{`${city} (${postalCode})`}</div>
        </ReactRouterLink>

        <div className={styles.pastilles}>
          <PastilleFiscality className={styles.pastille} tax={fiscality} ignore={TAX_TYPE_LMNP} />
          {programHasKitchen(program) && <KitchenIcon iconClassName={styles.pastille} />}
          {programIsControlledPrice(program) && (
            <SvgIcon className={styles.pastille} iconId="icon-prix-maitrises" />
          )}
          {programIsFNO(program) && <SvgIcon className={styles.pastille} iconId="icon-fno" />}
          {programIsHonoBoost(program) && (
            <SvgIcon className={styles.pastille} iconId="icon-honoboost" />
          )}
          {programIsFurnishEligible(program) && (
            <SvgIcon className={styles.pastille} iconId="icon-furnish-eligible" />
          )}
        </div>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            {Number(lotNumber) >= 1 ? (
              <>
                <Icon className={styles.svgIcon} icon="house" />
                <span className={styles.highlight}>{typology?.join(', ')}</span>
              </>
            ) : (
              LABEL_NO_LOT
            )}
          </li>
          <li className={styles.listItem}>
            <SvgIcon className={styles.svgIcon} iconId="icon-home-euro" />
            <ProgramPrice
              fiscality={fiscality}
              highlightClassName={styles.highlight}
              max={budgetMax}
              // Budget min is non nullable at this point
              min={budgetMin as NonNullable<typeof budgetMin>}
            />
          </li>
        </ul>
      </div>
    </div>
  );
}
