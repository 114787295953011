import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import LazyImage from 'commonUi/LazyImage/LazyImage';

import styles from './NewsItem.module.scss';

interface NewsItemProps {
  className?: string;
  href: string;
  image?: string;
  isHidden?: boolean;
  title: string;
}

export default function NewsItem({
  className,
  href,
  image = '/images/placeholders/283x159.png',
  isHidden = false,
  title,
}: NewsItemProps) {
  return (
    <Link
      className={classnames(className, styles.root)}
      to={href}
      target="_blank"
      rel="noreferrer"
      style={{ display: isHidden ? 'none' : undefined }}
    >
      <h3 className={styles.title}>{title}</h3>
      <LazyImage containerClassName={styles.picture} src={image} alt="" />
    </Link>
  );
}
