import React, { useContext, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useSWRImmutable from 'swr/immutable';
import classnames from 'classnames';

import { CONTRACT_KITCHEN_SELECTED } from 'settings/app';
import { KITCHEN_TYPE_OPTION } from 'settings/kitchen';
import {
  LABEL_ACTIVITY_OPTIONS,
  LABEL_ACTIVITY_SALES_AIDS,
  LABEL_ACTIVITY_SALES_AIDS_FOOTNOTE,
  LABEL_ADDITIONAL_DOCUMENTS,
  LABEL_BANK_CHECK,
  LABEL_BANK_TRANSFER,
  LABEL_BOOKING_FILE,
  LABEL_CASH_PAYMENT,
  LABEL_CHECK_REMITTANCE_DATE,
  LABEL_CHECK_REMITTANCE_DATE_ERROR,
  LABEL_CREDIT,
  LABEL_DEPOSIT_AMOUNT,
  LABEL_DEPOSIT_AMOUNT_ERROR,
  LABEL_DESCRIPTIVE_NOTICE,
  LABEL_DESCRIPTIVE_NOTICE_ERROR,
  LABEL_FINANCIAL_INFORMATIONS,
  LABEL_FISCALITY,
  LABEL_FORM_FIELDS_REQUIRED,
  LABEL_FORM_GENERATE_CONTRACT_PERCENTAGE_FEES_INFO1,
  LABEL_FORM_GENERATE_CONTRACT_PERCENTAGE_FEES_INFO2,
  LABEL_FORM_GENERATE_CONTRACT_PERCENTAGE_FEES_PASTILLE,
  LABEL_FORM_PREBOOK_INVESTOR,
  LABEL_FORM_PREBOOK_PRINCIPAL_RESIDENCE,
  LABEL_FORM_PREBOOK_SECOND_RESIDENCE,
  LABEL_FUNDING,
  LABEL_FUNDING_ERROR,
  LABEL_GENERATE_CONTRACT,
  LABEL_HT,
  LABEL_KITCHEN_OPTION_TOTAL,
  LABEL_LAST_STEP,
  LABEL_LOT_PRICE,
  LABEL_LOT_PRICE_ERROR,
  LABEL_NO,
  LABEL_PAYMENT_METHOD,
  LABEL_PAYMENT_METHOD_ERROR,
  LABEL_PERCENTAGE_FEES,
  LABEL_PERCENTAGE_FEES_ERROR,
  LABEL_PERSONAL_CONTRIBUTION,
  LABEL_PERSONAL_CONTRIBUTION_ERROR,
  LABEL_PROMOTION_ACTIVITIES_FORM,
  LABEL_PURCHASE_DESTINATION,
  LABEL_SALES_AID_KITCHEN,
  LABEL_SALES_AID_NOTARY,
  LABEL_SECURITY_DEPOSIT,
  LABEL_SIGNATURE_DATE,
  LABEL_SIGNATURE_DATE_ERROR,
  LABEL_SPECIAL_CONDITIONS_DOCUMENTS,
  LABEL_SPECIAL_CONDITIONS_DOCUMENTS_ERROR,
  LABEL_TAX_MANAGEMENT,
  LABEL_TAX_MANAGEMENT_ERROR,
  LABEL_TOTAL_LOAN,
  LABEL_TOTAL_LOAN_ERROR,
  LABEL_TTC,
} from 'settings/labels';
import {
  FIELD_CREDIT_AMOUNT,
  FIELD_CREDIT_CONTRIBUTION,
  FIELD_CREDIT_FUNDING,
  FIELD_DEPOSIT_AMOUNT,
  FIELD_DEPOSIT_CHECK_DATE,
  FIELD_DEPOSIT_MODE,
  FIELD_FINANCIAL_CONDITIONS,
  FIELD_FINANCIAL_DOCUMENTS,
  FIELD_FINANCIAL_DUTY_FREE,
  FIELD_FINANCIAL_FEES,
  FIELD_FINANCIAL_KITCHEN,
  FIELD_FINANCIAL_NOTE,
  FIELD_FINANCIAL_SIGNATURE,
  FIELD_FISCALITY,
  FIELD_LOT_FISCALITY_HIDDEN,
  FIELD_LOT_PRICE,
  FIELD_PROSPECT_DESTINATION,
  FIELD_PTZ,
  KEY_PREBOOK_PURCHASE_DESTINATION_FIRSTIME_BUYER,
  KEY_PREBOOK_PURCHASE_DESTINATION_INVESTOR,
  KEY_PREBOOK_PURCHASE_DESTINATION_PRINCIPAL_RESIDENCE,
  KEY_PREBOOK_PURCHASE_DESTINATION_SECOND_RESIDENCE,
} from 'settings/forms';
import { LOT_DEFAULT_SECURITY_DEPOSIT } from 'settings/lots';
import {
  TAX_TYPE_BRS,
  TAX_TYPE_DEMEMBREMENT,
  TAX_TYPE_LMNP,
  TAX_TYPE_LMNP_MANAGED,
  TAX_TYPE_NONE,
  TAX_TYPE_PLS,
} from 'settings/taxes';
import { TOKEN_PRICE } from 'settings/token';
import { COLORS } from 'settings/ui';

import type { Activity } from 'api/vi3pAPI/apiTypes/ActivityType';
import type { ContactPrescriptorType } from 'api/vi3pAPI/apiTypes/ContactPrescriptorType';
import type { ActivityChoices } from 'api/viCrmApi/apiTypes/ActivityChoices';
import type { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import SettingsContext from 'modules/App/Contexts/SettingsContext';
import userContext from 'modules/App/Contexts/userContext';

import { axiosViOffreInstance } from 'api/viOffresAPI/axiosInstance';

import { getActivityChoicesSalesAids } from 'services/activities';
import { addDays, dateMin, subDays } from 'services/date';
import { formatPrice, replaceTokens } from 'services/formatter';
import { getFirstPromotion } from 'services/promotions';
import { normalizeTax, taxPayloadNames } from 'services/taxes';

import Button from 'commonUi/Button/Button';
import SelectField from 'commonUi/SelectField/SelectField';
import DateField from 'commonUi/DateField/DateField';
import TextField from 'commonUi/TextField/TextField';
import { Pastille } from 'commonUi/Pastille/Pastille';
import PastilleFiscality from 'commonUi/Pastille/PastilleFiscality';
import { Checkbox } from 'commonUi/Checkbox/Checkbox';
import KitchenBlock from 'commonUi/KitchenEquippedInfosBlock/KitchenEquippedInfosBlock';
import DepositInfos from 'commonUi/DepositInfos/DepositInfos';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import { FieldPtz } from '../Fields/FieldPtz';
import { FieldFiscality } from '../Fields/FieldFiscality';

import styles from '../PageAction.module.scss';

const DESTINATION_OPTIONS = [
  {
    value: LABEL_FORM_PREBOOK_INVESTOR,
    key: KEY_PREBOOK_PURCHASE_DESTINATION_INVESTOR,
  },
  {
    value: LABEL_FORM_PREBOOK_PRINCIPAL_RESIDENCE,
    key: KEY_PREBOOK_PURCHASE_DESTINATION_PRINCIPAL_RESIDENCE,
  },
  {
    value: LABEL_FORM_PREBOOK_SECOND_RESIDENCE,
    key: KEY_PREBOOK_PURCHASE_DESTINATION_SECOND_RESIDENCE,
  },
];
const DUTY_FREE_OPTIONS = [
  {
    key: '1',
    value: 'HT',
  },
  {
    key: '0',
    value: 'TTC',
  },
];
const FUNDING_OPTIONS = [
  {
    key: 'true' as const,
    value: LABEL_CREDIT,
  },
  {
    key: 'false' as const,
    value: LABEL_CASH_PAYMENT,
  },
];
const PAYMENT_OPTIONS = [
  {
    key: 'cheque',
    value: LABEL_BANK_CHECK,
  },
  {
    key: 'virement',
    value: LABEL_BANK_TRANSFER,
  },
];

function getFieldFiscalityDefaultValue(
  normalizedTax: ReturnType<typeof normalizeTax>,
  purchaseDestination: string
) {
  switch (normalizedTax) {
    case TAX_TYPE_BRS:
    case TAX_TYPE_PLS:
      if (
        [
          KEY_PREBOOK_PURCHASE_DESTINATION_FIRSTIME_BUYER,
          KEY_PREBOOK_PURCHASE_DESTINATION_PRINCIPAL_RESIDENCE,
        ].includes(purchaseDestination)
      ) {
        return taxPayloadNames[TAX_TYPE_NONE];
      }
      return taxPayloadNames[normalizedTax];
    case TAX_TYPE_LMNP:
    case TAX_TYPE_LMNP_MANAGED:
    case TAX_TYPE_DEMEMBREMENT:
      return taxPayloadNames[normalizedTax];
    default:
      break;
  }
  return taxPayloadNames[TAX_TYPE_NONE];
}

function getLotPriceOptions(lot: LotTypeV2 | undefined) {
  const options: {
    key: string;
    value: string;
    price: number;
  }[] = [];
  if (!lot?.fiscalites[0]) {
    return options;
  }
  switch (normalizeTax(lot.fiscalites[0])) {
    case TAX_TYPE_DEMEMBREMENT:
      options.push({
        value: `${formatPrice(lot.prix.TVANormale.prixTTC)} ${LABEL_TTC} TVA ${
          lot.prix.TVANormale.tauxTva
        } %`,
        key: lot.prix.TVANormale.tauxTva,
        price: lot.prix.TVANormale.prixTTC,
      });
      break;

    case TAX_TYPE_LMNP:
    case TAX_TYPE_LMNP_MANAGED:
      options.push({
        value: `${formatPrice(lot.prix.prixHTHorsMobilier)} ${LABEL_HT} TVA ${
          lot.prix.TVANormale.tauxTva
        } %`,
        key: lot.prix.TVANormale.tauxTva,
        price: lot.prix.prixHTHorsMobilier,
      });
      break;

    case TAX_TYPE_NONE:
    case TAX_TYPE_BRS:
    case TAX_TYPE_PLS:
      options.push({
        value: `${formatPrice(lot.prix.TVANormale.prixTTC)} TTC TVA ${
          lot.prix.TVANormale.tauxTva
        } %`,
        key: lot.prix.TVANormale.tauxTva,
        price: lot.prix.TVANormale.prixTTC,
      });

      if (lot.prix.TVAalternative?.prixTTC) {
        options.push({
          value: `${formatPrice(lot.prix.TVAalternative.prixTTC)} TTC TVA ${
            lot.prix.TVAalternative.tauxTva
          } %`,
          key: lot.prix.TVAalternative.tauxTva,
          price: lot.prix.TVAalternative.prixTTC,
        });
      }
      break;
    default:
      break;
  }

  return options;
}

function makeDocumentsOptions(documents: string[]) {
  return documents?.map(document => ({ key: document, value: document }));
}

interface FormFinancialInformationsProps {
  activity: Activity;
  annexes: Record<string, string>;
  contactPrescriptor: ContactPrescriptorType | undefined;
  onClickOnBeforeButton: () => void;
  lotNumber: string;
  choices: ActivityChoices;
  programRef: string;
  loadingSubmit: boolean;
  defaultValues: {
    depositAmount: string;
    depositMethod: string;
    depositCheckDate: string;
    destination: string;
    fees: string;
    funding: typeof FUNDING_OPTIONS[number]['key'];
    loanAmount: string;
    loanContribution: string;
    dutyFree: '0' | '1';
    tva: string;
    signatureDate: string | null;
    tax: string;
    ptz: boolean;
    kitchen: string;
  };
  program?: ProgramTypeV2;
}

export default function FormFinancialInformations({
  activity,
  annexes,
  contactPrescriptor,
  onClickOnBeforeButton,
  lotNumber,
  choices,
  programRef,
  loadingSubmit,
  defaultValues,
  program,
}: FormFinancialInformationsProps) {
  const { settings } = useContext(SettingsContext);
  const isAttributionSwitchedOn = !!settings.cdo?.liste_attribution;
  const { isExcluded } = useContext(userContext);
  const { watch, control, formState, setValue } = useFormContext();
  const errors = formState?.errors;

  const { data: lot } = useSWRImmutable(
    lotNumber && programRef && (!isAttributionSwitchedOn || !isExcluded(programRef, lotNumber))
      ? `/programmes/${programRef}/lots/${lotNumber}?site=vi3p`
      : null,
    url => axiosViOffreInstance.get<LotTypeV2>(url).then(response => response.data)
  );

  const fundingType = watch(FIELD_CREDIT_FUNDING);
  const fieldFiscality = watch(FIELD_FISCALITY);
  const paymentMethod = watch(FIELD_DEPOSIT_MODE);
  const purchaseDestination = watch(FIELD_PROSPECT_DESTINATION);
  const tva = watch(FIELD_LOT_PRICE);

  const currentLotPriceOptions = getLotPriceOptions(lot).find(p => p.key === tva);

  const normalizedTax = normalizeTax(lot?.fiscalites[0]);
  const promotions = getFirstPromotion(lot?.promotions, false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (defaultValues.tax) {
      const normalizedDefaultTax = normalizeTax(defaultValues.tax);
      if (normalizedDefaultTax) {
        setValue(FIELD_FISCALITY, taxPayloadNames[normalizedDefaultTax], {
          shouldDirty: true,
        });
      }
    } else if (normalizedTax) {
      setValue(FIELD_FISCALITY, getFieldFiscalityDefaultValue(normalizedTax, purchaseDestination), {
        shouldDirty: true,
      });
    }
  }, [normalizedTax]);

  if (
    !choices.documents?.noticesDescriptives?.length ||
    !choices.documents?.conditionsParticulieres?.length
  ) {
    return null;
  }

  if (!normalizedTax) {
    return null;
  }

  const isPtzDisplay =
    defaultValues?.ptz ||
    ([
      KEY_PREBOOK_PURCHASE_DESTINATION_FIRSTIME_BUYER,
      KEY_PREBOOK_PURCHASE_DESTINATION_PRINCIPAL_RESIDENCE,
    ].includes(purchaseDestination) &&
      ![TAX_TYPE_LMNP, TAX_TYPE_LMNP_MANAGED, TAX_TYPE_DEMEMBREMENT].includes(normalizedTax));

  const signMinDate = activity.field_datereservation_ac
    ? subDays(activity.field_dateexpiration_ac, contactPrescriptor?.dureePrereservations ?? 5)
    : undefined;
  const signMaxDate =
    activity.field_dateexpiration_ac && activity.field_datereservation_ac
      ? subDays(
          dateMin(
            activity.field_dateexpiration_ac,
            addDays(
              activity.field_datereservation_ac,
              contactPrescriptor?.dureePrereservations ?? 5
            )
          ),
          1 // Substract 1 day to max date because the higher bound is excluded
        )
      : undefined;
  const salesAids = getActivityChoicesSalesAids(choices);

  return (
    <>
      <div className={styles.form}>
        <div className={styles.title}>{LABEL_FINANCIAL_INFORMATIONS}</div>

        <div className={styles.grid}>
          <div className={styles.input}>
            <Controller
              name={FIELD_PROSPECT_DESTINATION}
              control={control}
              defaultValue={defaultValues?.destination}
              render={props => (
                <SelectField
                  id={FIELD_PROSPECT_DESTINATION}
                  label={LABEL_PURCHASE_DESTINATION}
                  onChange={optionValue => props?.field.onChange(optionValue)}
                  options={DESTINATION_OPTIONS}
                  required
                  value={props.field.value}
                />
              )}
            />
          </div>
          <div className={styles.input}>
            {![TAX_TYPE_LMNP, TAX_TYPE_LMNP_MANAGED, TAX_TYPE_DEMEMBREMENT].includes(
              normalizedTax
            ) && (
              <Controller
                name={FIELD_FINANCIAL_FEES}
                control={control}
                render={props => (
                  <TextField
                    id={FIELD_FINANCIAL_FEES}
                    error={errors?.[FIELD_FINANCIAL_FEES]}
                    helperText={errors?.[FIELD_FINANCIAL_FEES] && LABEL_PERCENTAGE_FEES_ERROR}
                    label={LABEL_PERCENTAGE_FEES}
                    labelNbAdditionalStars={1}
                    onChange={ev =>
                      props?.field.onChange(Number(ev.target.value) > 10 ? 10 : ev.target.value)
                    }
                    endAdornmentText="%"
                    required
                    type="number"
                    inputProps={{
                      max: 10,
                      min: 0,
                      step: 0.01,
                    }}
                    value={props.field.value}
                  />
                )}
              />
            )}
          </div>
          <div className={styles.input}>
            <span className={styles.subtitle}>{LABEL_FISCALITY}</span>
            {normalizedTax !== TAX_TYPE_NONE && (
              <>
                <PastilleFiscality className={styles.pastille} tax={lot?.fiscalites[0]} />
                {normalizedTax === TAX_TYPE_LMNP && (
                  <FieldFiscality normalizedTax={normalizedTax} />
                )}
              </>
            )}
            {normalizedTax === TAX_TYPE_NONE && (
              <span className={styles.noFiscality}>{LABEL_NO}</span>
            )}
            <FieldPtz
              name={FIELD_PTZ}
              show={isPtzDisplay}
              defaultValue={defaultValues?.ptz}
              noLabel={!!defaultValues?.tax}
            />
          </div>
          <div className={classnames(styles.input, styles.percentageFeesTips)}>
            {![TAX_TYPE_LMNP, TAX_TYPE_LMNP_MANAGED, TAX_TYPE_DEMEMBREMENT].includes(
              normalizedTax
            ) && (
              <>
                <Pastille
                  noContainer
                  className={styles.titleTag}
                  labelClassName={styles.titleTagLabel}
                  label={LABEL_FORM_GENERATE_CONTRACT_PERCENTAGE_FEES_PASTILLE}
                  bgColor={COLORS.SECONDARY.RED}
                />
                <p>{LABEL_FORM_GENERATE_CONTRACT_PERCENTAGE_FEES_INFO1}</p>
                <p className={styles.bold}>{LABEL_FORM_GENERATE_CONTRACT_PERCENTAGE_FEES_INFO2}</p>
              </>
            )}
          </div>
          <div
            className={classnames(styles.input, {
              [styles.displayColumn]: promotions,
            })}
          >
            <Controller
              name={FIELD_LOT_PRICE}
              control={control}
              defaultValue={defaultValues.tva || lot?.prix?.TVANormale?.tauxTva}
              render={props => (
                <SelectField
                  error={errors?.[FIELD_LOT_PRICE]}
                  helperText={errors?.[FIELD_LOT_PRICE] && LABEL_LOT_PRICE_ERROR}
                  id={FIELD_LOT_PRICE}
                  label={LABEL_LOT_PRICE}
                  onChange={props?.field.onChange}
                  options={getLotPriceOptions(lot)}
                  required
                  value={props.field.value}
                />
              )}
            />
            {promotions && <div className={styles.infoText}>{LABEL_PROMOTION_ACTIVITIES_FORM}</div>}
          </div>
          <div className={styles.input}>
            <Controller
              name={FIELD_FINANCIAL_SIGNATURE}
              control={control}
              defaultValue={defaultValues.signatureDate}
              render={props => (
                <DateField
                  error={errors?.[FIELD_FINANCIAL_SIGNATURE]}
                  helperText={errors?.[FIELD_FINANCIAL_SIGNATURE] && LABEL_SIGNATURE_DATE_ERROR}
                  id={FIELD_FINANCIAL_SIGNATURE}
                  label={LABEL_SIGNATURE_DATE}
                  name={FIELD_FINANCIAL_SIGNATURE}
                  onChange={ev => props?.field.onChange(ev.target.value)}
                  readOnly
                  required
                  value={props.field.value}
                  minDate={signMinDate}
                  maxDate={signMaxDate}
                />
              )}
            />
          </div>
        </div>
        <div className={styles.title}>{LABEL_FUNDING}</div>
        <div className={styles.grid}>
          <div className={styles.input}>
            <Controller
              name={FIELD_CREDIT_FUNDING}
              control={control}
              defaultValue={defaultValues?.funding}
              render={props => (
                <SelectField
                  error={errors?.[FIELD_CREDIT_FUNDING]}
                  helperText={errors?.[FIELD_CREDIT_FUNDING] && LABEL_FUNDING_ERROR}
                  id={FIELD_CREDIT_FUNDING}
                  label={LABEL_FUNDING}
                  onChange={optionValue => props?.field.onChange(optionValue)}
                  options={FUNDING_OPTIONS}
                  required
                  value={props.field.value}
                />
              )}
            />
          </div>
          {fieldFiscality === 'LMNP' && (
            <div className={styles.input}>
              <Controller
                name={FIELD_FINANCIAL_DUTY_FREE}
                control={control}
                defaultValue={defaultValues?.dutyFree}
                render={props => (
                  <SelectField
                    error={errors?.[FIELD_FINANCIAL_DUTY_FREE]}
                    helperText={errors?.[FIELD_FINANCIAL_DUTY_FREE] && LABEL_TAX_MANAGEMENT_ERROR}
                    id={FIELD_FINANCIAL_DUTY_FREE}
                    label={LABEL_TAX_MANAGEMENT}
                    onChange={optionValue => props?.field.onChange(optionValue)}
                    options={DUTY_FREE_OPTIONS}
                    required
                    value={props.field.value}
                  />
                )}
              />
            </div>
          )}
          {fundingType === 'true' && (
            <>
              <div className={styles.input}>
                <Controller
                  name={FIELD_CREDIT_AMOUNT}
                  control={control}
                  defaultValue={defaultValues?.loanAmount}
                  render={props => (
                    <TextField
                      id={FIELD_CREDIT_AMOUNT}
                      error={errors?.[FIELD_CREDIT_AMOUNT]}
                      helperText={errors?.[FIELD_CREDIT_AMOUNT] && LABEL_TOTAL_LOAN_ERROR}
                      label={LABEL_TOTAL_LOAN}
                      onChange={ev => props?.field.onChange(ev.target.value)}
                      endAdornmentText="€"
                      required
                      type="number"
                      value={props.field.value}
                    />
                  )}
                />
              </div>

              <div className={styles.input}>
                <Controller
                  name={FIELD_CREDIT_CONTRIBUTION}
                  control={control}
                  defaultValue={defaultValues?.loanContribution}
                  render={props => (
                    <TextField
                      id={FIELD_CREDIT_CONTRIBUTION}
                      error={errors?.[FIELD_CREDIT_CONTRIBUTION]}
                      helperText={
                        errors?.[FIELD_CREDIT_CONTRIBUTION] && LABEL_PERSONAL_CONTRIBUTION_ERROR
                      }
                      label={LABEL_PERSONAL_CONTRIBUTION}
                      onChange={ev => props?.field.onChange(ev.target.value)}
                      endAdornmentText="€"
                      required
                      type="number"
                      value={props.field.value}
                    />
                  )}
                />
              </div>
            </>
          )}
        </div>

        <div className={classnames(styles.title, styles.titleLine)}>
          {LABEL_SECURITY_DEPOSIT}
          <DepositInfos
            className={styles.deposit}
            bicDepotGarantie={program?.depotGarantie?.bicDepotGarantie}
            ibanDepotGarantie={program?.depotGarantie?.ibanDepotGarantie}
            ordreDepotGarantie={program?.depotGarantie?.ordreDepotGarantie}
            nomBanqueDepotGarantie={program?.depotGarantie?.nomBanqueDepotGarantie}
            modePaiement={program?.depotGarantie?.modePaiement}
            montantDepot={
              program?.depotGarantie?.montantDepotGarantie
                ? formatPrice(program.depotGarantie.montantDepotGarantie)
                : LOT_DEFAULT_SECURITY_DEPOSIT
            }
          />
        </div>
        <div className={styles.grid}>
          <div className={styles.input}>
            <Controller
              name={FIELD_DEPOSIT_AMOUNT}
              control={control}
              defaultValue={defaultValues?.depositAmount}
              render={props => (
                <TextField
                  id={FIELD_DEPOSIT_AMOUNT}
                  error={errors?.[FIELD_DEPOSIT_AMOUNT]}
                  helperText={errors?.[FIELD_DEPOSIT_AMOUNT] && LABEL_DEPOSIT_AMOUNT_ERROR}
                  label={LABEL_DEPOSIT_AMOUNT}
                  onChange={ev => props?.field.onChange(ev.target.value)}
                  endAdornmentText="€"
                  required
                  type="number"
                  value={props.field.value}
                />
              )}
            />
          </div>
          <div className={styles.input}>
            <Controller
              name={FIELD_DEPOSIT_MODE}
              control={control}
              defaultValue={defaultValues?.depositMethod || 'virement'}
              render={props => (
                <SelectField
                  error={errors?.[FIELD_DEPOSIT_MODE]}
                  helperText={errors?.[FIELD_DEPOSIT_MODE] && LABEL_PAYMENT_METHOD_ERROR}
                  id={FIELD_DEPOSIT_MODE}
                  label={LABEL_PAYMENT_METHOD}
                  onChange={optionValue => {
                    if (optionValue === 'virement') {
                      setValue(FIELD_DEPOSIT_CHECK_DATE, null);
                    }
                    props?.field.onChange(optionValue);
                  }}
                  options={PAYMENT_OPTIONS}
                  required
                  value={props.field.value}
                />
              )}
            />
          </div>
          {paymentMethod === 'cheque' && (
            <div className={styles.input}>
              <Controller
                name={FIELD_DEPOSIT_CHECK_DATE}
                control={control}
                defaultValue={defaultValues?.depositCheckDate}
                render={props => (
                  <DateField
                    error={errors?.[FIELD_DEPOSIT_CHECK_DATE]}
                    helperText={
                      errors?.[FIELD_DEPOSIT_CHECK_DATE] && LABEL_CHECK_REMITTANCE_DATE_ERROR
                    }
                    id={FIELD_DEPOSIT_CHECK_DATE}
                    label={LABEL_CHECK_REMITTANCE_DATE}
                    name={FIELD_DEPOSIT_CHECK_DATE}
                    onChange={ev => props?.field.onChange(ev.target.value)}
                    required
                    value={props.field.value}
                  />
                )}
              />
            </div>
          )}
        </div>
        {choices.packs.maCuisineEquipee.type === KITCHEN_TYPE_OPTION && (
          <>
            <div className={styles.title}>{LABEL_ACTIVITY_OPTIONS}</div>
            <div className={classnames(styles.grid, styles.kitchen)}>
              <div className={styles.input}>
                <Controller
                  name={FIELD_FINANCIAL_KITCHEN}
                  control={control}
                  defaultValue={defaultValues.kitchen === CONTRACT_KITCHEN_SELECTED}
                  render={props => (
                    <Checkbox
                      checked={props.field.value}
                      handleChange={ev => props?.field.onChange(ev.target.checked)}
                      color="primary"
                      classes={{
                        root: classnames(styles.formControlLabel, styles.kitchenCheckbox),
                        label: styles.checkboxLabel,
                        labelTitle: styles.checkboxLabelTitle,
                      }}
                      label={{
                        title: <KitchenBlock lot={lot} />,
                        subtitle:
                          currentLotPriceOptions &&
                          replaceTokens(LABEL_KITCHEN_OPTION_TOTAL, {
                            [TOKEN_PRICE]: formatPrice(
                              choices.packs.maCuisineEquipee.prix +
                                Number(currentLotPriceOptions.price),
                              '€',
                              true
                            ),
                          }),
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </>
        )}
        {salesAids.length > 0 && (
          <>
            <div className={styles.title}>{LABEL_ACTIVITY_SALES_AIDS}*</div>
            <div className={styles.section}>
              {salesAids.includes(LABEL_SALES_AID_NOTARY) && (
                <div className={styles.salesAidsItem}>
                  <SvgIcon iconId="icon-fno" />
                </div>
              )}
              {salesAids.includes(LABEL_SALES_AID_KITCHEN) && (
                <div className={styles.salesAidsItem}>
                  <KitchenBlock lot={lot} />
                </div>
              )}
              <ul className="checkmark-list">
                {salesAids.map(
                  aid =>
                    aid !== LABEL_SALES_AID_NOTARY &&
                    aid !== LABEL_SALES_AID_KITCHEN && <li key={aid}>{aid}</li>
                )}
              </ul>
            </div>
            <div className={styles.info}>{LABEL_ACTIVITY_SALES_AIDS_FOOTNOTE}</div>
          </>
        )}
        <div className={styles.title}>{LABEL_BOOKING_FILE}</div>
        <div className={styles.grid}>
          <div className={styles.input}>
            <Controller
              name={FIELD_FINANCIAL_NOTE}
              control={control}
              defaultValue={
                choices.documents?.noticesDescriptives?.length === 1
                  ? choices.documents?.noticesDescriptives?.[0]
                  : null
              }
              render={props => (
                <SelectField
                  error={errors?.[FIELD_FINANCIAL_NOTE]}
                  helperText={errors?.[FIELD_FINANCIAL_NOTE] && LABEL_DESCRIPTIVE_NOTICE_ERROR}
                  id={FIELD_FINANCIAL_NOTE}
                  label={LABEL_DESCRIPTIVE_NOTICE}
                  onChange={optionValue => {
                    props?.field.onChange(optionValue);
                  }}
                  options={makeDocumentsOptions(choices.documents?.noticesDescriptives)}
                  required
                  value={props.field.value}
                />
              )}
            />
          </div>
          <div className={styles.input}>
            <Controller
              name={FIELD_FINANCIAL_CONDITIONS}
              control={control}
              defaultValue={
                choices.documents?.conditionsParticulieres?.length === 1
                  ? choices.documents?.conditionsParticulieres?.[0]
                  : null
              }
              render={props => (
                <SelectField
                  error={errors?.[FIELD_FINANCIAL_CONDITIONS]}
                  helperText={
                    errors?.[FIELD_FINANCIAL_CONDITIONS] && LABEL_SPECIAL_CONDITIONS_DOCUMENTS_ERROR
                  }
                  id={FIELD_FINANCIAL_CONDITIONS}
                  label={LABEL_SPECIAL_CONDITIONS_DOCUMENTS}
                  onChange={optionValue => props?.field.onChange(optionValue)}
                  options={makeDocumentsOptions(choices.documents?.conditionsParticulieres)}
                  required
                  value={props.field.value}
                />
              )}
            />
          </div>
        </div>

        {choices.documents?.annexesComplementaires?.length > 0 && (
          <>
            <hr className={styles.separator} />

            <div className={styles.title}>
              <span>{LABEL_ADDITIONAL_DOCUMENTS}</span>
              <Pastille
                bgColor="#F3F3F3"
                className={styles.pastille}
                textColor="#646464"
                label="facultatif"
              />
            </div>
            <div className={classnames(styles.input, styles.inputAdditionalDocuments)}>
              {Object.entries(annexes).map(([fieldName, fileName]) => (
                <Controller
                  key={fieldName}
                  name={`${FIELD_FINANCIAL_DOCUMENTS}.${fieldName}`}
                  control={control}
                  defaultValue=""
                  render={props => (
                    <Checkbox
                      checked={props.field.value}
                      handleChange={ev => props?.field.onChange(ev.target.checked)}
                      color="primary"
                      label={fileName}
                      classes={{ root: styles.formControlLabel }}
                    />
                  )}
                />
              ))}
            </div>
          </>
        )}

        <Controller
          name={FIELD_LOT_FISCALITY_HIDDEN}
          control={control}
          defaultValue={taxPayloadNames[normalizedTax]}
          render={props => <input type="hidden" value={props.field.value} />}
        />

        <span className={styles.additionalInformation}>* {LABEL_FORM_FIELDS_REQUIRED}</span>
      </div>
      <div className={styles.footer}>
        <div className={styles.cancelButton}>
          <Button fullWidth variant="contained" color="secondary" onClick={onClickOnBeforeButton}>
            {LABEL_LAST_STEP}
          </Button>
        </div>
        <div className={styles.createButton}>
          <Button
            loading={loadingSubmit}
            disabled={!formState?.isDirty}
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
          >
            {LABEL_GENERATE_CONTRACT}
          </Button>
        </div>
      </div>
    </>
  );
}
