import React, { forwardRef, useContext, useRef } from 'react';
import Slider from 'react-slick';
import classnames from 'classnames';

import { LABEL_HP_CONTACTS_SUBTITLE, LABEL_HP_CONTACTS_TITLE } from 'settings/labels';

import type { SliderRef } from 'types/slider';

import ContactsContext from 'modules/App/Contexts/ContactsContext';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import Contact from './Contact';

import styles from './ContactsSlider.module.scss';

export default forwardRef<HTMLElement>(function ContactsSlider(_, ref) {
  const { getContacts, isLoading } = useContext(ContactsContext);
  const sliderRef = useRef<SliderRef>();

  if (isLoading) {
    return null;
  }

  return (
    <section ref={ref} className={styles.root}>
      <header className={styles.header}>
        <div className={styles.text}>
          <h2 className={styles.title}>{LABEL_HP_CONTACTS_TITLE}</h2>
          <div className={styles.subtitle}>{LABEL_HP_CONTACTS_SUBTITLE}</div>
        </div>

        <div className={styles.buttons}>
          <button
            type="button"
            className={classnames(styles.button, styles.prev)}
            onClick={() => sliderRef.current?.slickPrev()}
          >
            <Icon className={styles.icon} icon="chevron-down" />
          </button>
          <button
            type="button"
            className={classnames(styles.button, styles.next)}
            onClick={() => sliderRef.current?.slickNext()}
          >
            <Icon className={styles.icon} icon="chevron-down" />
          </button>
        </div>
      </header>

      <Slider
        ref={sliderRef}
        className={styles.slider}
        arrows={false}
        dots={false}
        infinite
        slidesToScroll={5}
        slidesToShow={5}
        speed={1000}
        responsive={[
          {
            breakpoint: 767,
            settings: {
              slidesToScroll: 1,
              slidesToShow: 1,
              variableWidth: true,
            },
          },
        ]}
      >
        {getContacts()
          .filter(({ disabled_homepage: disabled }) => !disabled)
          .map(
            ({
              prenom,
              nom,
              photo,
              photo_dimensions: photoDim,
              region,
              email,
              telephone,
              pictogram,
            }) => (
              <Contact
                key={email}
                className={styles.contact}
                description={region}
                email={email}
                firstName={prenom}
                image={photo}
                imageDimensions={photoDim}
                lastName={nom}
                phone={telephone}
                picto={pictogram}
              />
            )
          )}
      </Slider>
    </section>
  );
});
