import React from 'react';
import classnames from 'classnames';

import {
  LABEL_CITIES,
  LABEL_DELIVERY,
  LABEL_FISCALITY,
  LABEL_FLOOR,
  LABEL_LOT,
  LABEL_PARKING,
  LABEL_PRICE_COMMERCIAL_TTC,
  LABEL_PRICE_IMMO_HT,
  LABEL_PRICE_INCLUDING_TAX_TTC,
  LABEL_PRICE_MOB_HT,
  LABEL_PRICE_REDUCED_VAT,
  LABEL_PROFITABILITY,
  LABEL_RENT,
  LABEL_RENT_YEARLY,
  LABEL_SORT_BY,
  LABEL_STATUS,
  LABEL_SURFACE,
  LABEL_SURFACE_ANNEX,
  LABEL_TYPE,
} from 'settings/labels';
import {
  SORT_LOT_BY_DELIVERY,
  SORT_LOT_BY_FLOOR,
  SORT_LOT_BY_KIND,
  SORT_LOT_BY_NUMBER,
  SORT_LOT_BY_PARKING,
  SORT_LOT_BY_PRICE_IMMO_HT,
  SORT_LOT_BY_PRICE_INCLUDING_TAX,
  SORT_LOT_BY_PRICE_MOB_HT,
  SORT_LOT_BY_PRICE_REDUCED_VAT,
  SORT_LOT_BY_PROFITABILITY,
  SORT_LOT_BY_PROGRAM,
  SORT_LOT_BY_RENT_CUSTOM,
  SORT_LOT_BY_RENT_TTC,
  SORT_LOT_BY_STATUS,
  SORT_LOT_BY_SURFACE,
  SORT_LOT_BY_SURFACE_ANNEX,
  SORT_LOT_BY_TAX,
  SORT_LOT_BY_TYPE,
} from 'settings/search';

import type { LotSortBy, LotSortOrder } from 'api/viOffresAPI/apiTypes/LotType';

import DropdownList from 'commonUi/DropdownList/DropdownList';
import SortItem from 'commonUi/SortItem/SortItem';

interface LotDropdownSortProps {
  classes?: Partial<Record<'root' | 'button', string>>;
  className?: string;
  taxes: string[];
  reorderLots: (sortBy: LotSortBy) => void;
  lotsSortBy: LotSortBy;
  lotsSortOrder: LotSortOrder;
}

export default function LotDropdownSort({
  classes = {},
  className,
  taxes,
  reorderLots,
  lotsSortBy,
  lotsSortOrder,
}: LotDropdownSortProps) {
  let selectedTax = 'multi';
  if (taxes.length >= 1) {
    const [firstTax] = taxes;
    selectedTax = firstTax;
  }
  const isMulti = selectedTax === 'multi';
  const isLMNP = selectedTax === 'LMNP';
  const isDemembrement = selectedTax === 'Démembrement';

  return (
    <DropdownList
      id="sort"
      buttonClassName={classes.button}
      className={classnames(className, classes.root)}
      label={LABEL_SORT_BY}
    >
      <SortItem
        activated={isMulti}
        onClick={reorderLots}
        sortBy={lotsSortBy}
        sortOrder={lotsSortOrder}
        title={LABEL_FISCALITY}
        type={SORT_LOT_BY_TAX}
      />
      <SortItem
        onClick={reorderLots}
        sortBy={lotsSortBy}
        sortOrder={lotsSortOrder}
        title={LABEL_CITIES}
        type={SORT_LOT_BY_PROGRAM}
      />
      <SortItem
        activated={isLMNP}
        onClick={reorderLots}
        sortBy={lotsSortBy}
        sortOrder={lotsSortOrder}
        title="Nature"
        type={SORT_LOT_BY_TYPE}
      />
      <SortItem
        onClick={reorderLots}
        sortBy={lotsSortBy}
        sortOrder={lotsSortOrder}
        title={LABEL_LOT}
        type={SORT_LOT_BY_NUMBER}
      />
      <SortItem
        onClick={reorderLots}
        sortBy={lotsSortBy}
        sortOrder={lotsSortOrder}
        title={LABEL_TYPE}
        type={SORT_LOT_BY_KIND}
      />
      <SortItem
        onClick={reorderLots}
        sortBy={lotsSortBy}
        sortOrder={lotsSortOrder}
        title={LABEL_FLOOR}
        type={SORT_LOT_BY_FLOOR}
      />
      <SortItem
        onClick={reorderLots}
        sortBy={lotsSortBy}
        sortOrder={lotsSortOrder}
        title={LABEL_SURFACE}
        type={SORT_LOT_BY_SURFACE}
      />
      <SortItem
        onClick={reorderLots}
        sortBy={lotsSortBy}
        sortOrder={lotsSortOrder}
        title={LABEL_SURFACE_ANNEX}
        type={SORT_LOT_BY_SURFACE_ANNEX}
      />
      <SortItem
        onClick={reorderLots}
        sortBy={lotsSortBy}
        sortOrder={lotsSortOrder}
        title={LABEL_PARKING}
        type={SORT_LOT_BY_PARKING}
      />

      {/* Price behavior */}
      <SortItem
        activated={isMulti}
        onClick={reorderLots}
        sortBy={lotsSortBy}
        sortOrder={lotsSortOrder}
        title={LABEL_PRICE_REDUCED_VAT}
        type={SORT_LOT_BY_PRICE_REDUCED_VAT}
      />
      <SortItem
        activated={isLMNP}
        onClick={reorderLots}
        sortBy={lotsSortBy}
        sortOrder={lotsSortOrder}
        title={LABEL_PRICE_IMMO_HT}
        type={SORT_LOT_BY_PRICE_IMMO_HT}
      />
      <SortItem
        activated={isMulti}
        onClick={reorderLots}
        sortBy={lotsSortBy}
        sortOrder={lotsSortOrder}
        title={LABEL_PRICE_INCLUDING_TAX_TTC}
        type={SORT_LOT_BY_PRICE_INCLUDING_TAX}
      />
      <SortItem
        activated={isLMNP}
        onClick={reorderLots}
        sortBy={lotsSortBy}
        sortOrder={lotsSortOrder}
        title={LABEL_PRICE_MOB_HT}
        type={SORT_LOT_BY_PRICE_MOB_HT}
      />
      <SortItem
        activated={isDemembrement}
        onClick={reorderLots}
        sortBy={lotsSortBy}
        sortOrder={lotsSortOrder}
        title={LABEL_PRICE_COMMERCIAL_TTC}
        type={SORT_LOT_BY_PRICE_INCLUDING_TAX}
      />

      {/* Rent behavior */}
      <SortItem
        activated={isMulti}
        onClick={reorderLots}
        sortBy={lotsSortBy}
        sortOrder={lotsSortOrder}
        title={LABEL_RENT}
        type={SORT_LOT_BY_RENT_TTC}
      />
      <SortItem
        activated={isLMNP}
        onClick={reorderLots}
        sortBy={lotsSortBy}
        sortOrder={lotsSortOrder}
        title={LABEL_RENT_YEARLY}
        type={SORT_LOT_BY_RENT_CUSTOM}
      />

      {/* Profitability behavior */}
      <SortItem
        activated={isMulti || isLMNP}
        onClick={reorderLots}
        sortBy={lotsSortBy}
        sortOrder={lotsSortOrder}
        title={LABEL_PROFITABILITY}
        type={SORT_LOT_BY_PROFITABILITY}
      />

      {/* Delivery behavior */}
      <SortItem
        activated={isMulti || isLMNP}
        onClick={reorderLots}
        sortBy={lotsSortBy}
        sortOrder={lotsSortOrder}
        title={LABEL_DELIVERY}
        type={SORT_LOT_BY_DELIVERY}
      />

      <SortItem
        onClick={reorderLots}
        sortBy={lotsSortBy}
        sortOrder={lotsSortOrder}
        title={LABEL_STATUS}
        type={SORT_LOT_BY_STATUS}
      />
    </DropdownList>
  );
}
