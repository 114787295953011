import React, { useContext } from 'react';
import type { ComponentProps } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { formatActivityDate } from 'services/date';
import { formatPrice, replaceTokens } from 'services/formatter';
import { htmlEntityDecode } from 'services/stringHtml';
import {
  ACTIVITY_STATUS_ACTIVE,
  ACTIVITY_STATUS_WON,
  ACTIVITY_TYPE_BOOKING,
  ACTIVITY_TYPE_PRE_BOOKING,
  ACTIVITY_TYPE_SALE,
  ACTIVITY_TYPE_TO_TAB_ID_MAPPING,
} from 'settings/activity';
import { LABEL_CLIENT_NAME, LABEL_DATE_EXPIRATION, LABEL_TTC_PRICE } from 'settings/labels';
import { TAX_TYPE_LMNP } from 'settings/taxes';
import { TOKEN_DATE } from 'settings/token';

import type { Activity } from 'api/vi3pAPI/apiTypes/ActivityType';

import programLotContext from 'modules/App/Contexts/programLotContext';

import ActivityStepsList from 'commonUi/ActivityStep/ActivityStepsList';
import PastilleFiscality from 'commonUi/Pastille/PastilleFiscality';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import Tooltip from 'commonUi/Tooltip/Tooltip';

import ActivityStatus from '../ActivityTable/ActivityStatus';
import ActivityActions from '../ActivityTable/ActivityActions';

import styles from './ActivityMobileDetail.module.scss';

interface ActivityMobileDetail {
  activity: Activity;
  apiUrls: ComponentProps<typeof ActivityActions>['apiUrls'];
  className?: string;
  reports?: boolean;
  setOpenActivityId: React.Dispatch<React.SetStateAction<string>>;
}

export default function ActivityMobileDetail({
  activity,
  apiUrls,
  className,
  reports,
  setOpenActivityId,
}: ActivityMobileDetail) {
  const { getProgram } = useContext(programLotContext);
  const program = getProgram(activity.field_referenceprogramme_ac);

  if (!activity) {
    return null;
  }

  const formattedExpirationDate = activity.field_dateexpiration_ac
    ? formatActivityDate(activity.field_dateexpiration_ac, 'DD/MM/YYYY à HH:mm')
    : undefined;

  function openDrawer() {
    setOpenActivityId(activity.field_idcrm_ac);
  }

  return (
    <div className={classnames(className, styles.root)}>
      <div className={styles.main}>
        <div className={styles.tags}>
          <PastilleFiscality tax={activity.field_dispositiffiscal_ac} ignore={TAX_TYPE_LMNP} />
          <ActivityStatus activity={activity} showSubtitle={false} />
          {activity.field_numerolot_ac && (
            <button type="button" className={styles.lot} onClick={openDrawer}>
              {activity.field_numerolot_ac}
            </button>
          )}
        </div>

        <div className={styles.programContainer}>
          {program && (
            <Link
              className={styles.program}
              to={`/programme/page/${program.ref}/prix`}
              target="_blank"
              onClick={event => event.stopPropagation()}
            >
              <span className={styles.programName}>{htmlEntityDecode(program.name)}</span>
              <span className={styles.programLocation}>
                {`${htmlEntityDecode(program.city)} (${htmlEntityDecode(program.postalCode)})`}
              </span>
            </Link>
          )}
          {reports && (
            <Tooltip arrow placement="top" title="Accéder au suivi du chantier">
              <Link
                className={styles.programReports}
                to={`/programme/page/${activity.field_referenceprogramme_ac}/chantiers?from=${
                  ACTIVITY_TYPE_TO_TAB_ID_MAPPING[activity.field_type_ac]
                }`}
                target="_blank"
                onClick={ev => ev.stopPropagation()}
              >
                <SvgIcon className={styles.icon} iconId="icon-progress-bicolor" />
              </Link>
            </Tooltip>
          )}
        </div>
      </div>
      {((activity.field_type_ac === ACTIVITY_TYPE_PRE_BOOKING &&
        activity.field_statutnom_ac !== ACTIVITY_STATUS_WON) ||
        activity.field_type_ac === ACTIVITY_TYPE_BOOKING ||
        activity.field_type_ac === ACTIVITY_TYPE_SALE) && (
        <div className={classnames(styles.stepsContainer, styles.separator)}>
          <ActivityStepsList className={styles.steps} activity={activity} />
        </div>
      )}
      <dl className={classnames(styles.meta, styles.separator)}>
        <div className={styles.metaLine}>
          <dt>{LABEL_CLIENT_NAME}</dt>
          <dd>
            {[activity.field_prospectprenom_ac, activity.field_prospectnom_ac]
              .filter(Boolean)
              .join(' ') || '-'}
          </dd>
        </div>
        <div className={styles.metaLine}>
          <dt>{LABEL_TTC_PRICE}</dt>
          <dd>
            <strong>
              {activity.field_prixlotttc_ac ? formatPrice(activity.field_prixlotttc_ac) : '-'}
            </strong>
          </dd>
        </div>
        {activity.field_statutnom_ac === ACTIVITY_STATUS_ACTIVE && formattedExpirationDate && (
          <div className={styles.metaLine}>
            <dt>{replaceTokens(LABEL_DATE_EXPIRATION, { [TOKEN_DATE]: '' })}</dt>
            <dd>{formattedExpirationDate}</dd>
          </div>
        )}
      </dl>
      <ActivityActions className={styles.actions} activity={activity} apiUrls={apiUrls} />
    </div>
  );
}
