import React, { useContext, useState } from 'react';
import classnames from 'classnames';

import { extractVideoIdFromUrl } from 'services/videos';

import type { MediaType, VideoType } from 'types/medias';
import { mediaIsVideo } from 'types/medias';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import FieldVideo from 'commonUi/FieldVideo/FieldVideo';
import FieldImage from 'commonUi/FieldImage/FieldImage';
import SlickSlider from 'commonUi/SlickSlider/SlickSlider';

import MediaThumbnail from './MediaThumbnail';

import styles from './MediasVerticalSlider.module.scss';

interface MediasVerticalSliderProps {
  classes?: Partial<
    Record<
      | 'root'
      | 'activeContainer'
      | 'activeMedia'
      | 'activeSlide'
      | 'video'
      | 'galleryBtn'
      | 'galleryBtnIcon'
      | 'slide'
      | 'slider'
      | 'slideVideo'
      | 'noSlider',
      string
    >
  >;
  medias?: (MediaType | VideoType)[];
  onActiveClick?: (index: number) => void;
  sliderSettings?: any;
  startingIndex?: number;
}

export default function MediasVerticalSlider({
  classes = {},
  medias = [],
  onActiveClick,
  sliderSettings: overrideSliderSettings,
  startingIndex = 0,
}: MediasVerticalSliderProps) {
  const { isResponsive } = useContext(ResponsiveContext);
  const [activeMediaIndex, setActiveMediaIndex] = useState(startingIndex % medias.length);
  const activeMedia = medias[activeMediaIndex];

  const sliderSettings = {
    prevArrow: (
      <button type="button">
        <Icon icon="chevron-down" />
      </button>
    ),
    nextArrow: (
      <button type="button">
        <Icon icon="chevron-down" />
      </button>
    ),
    slidesToShow: 4,
    slidesToScroll: 1,
    vertical: !isResponsive,
    infinite: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
    ...overrideSliderSettings,
  };

  const renderSlides = (medias: MediasVerticalSliderProps['medias']) =>
    medias?.map((media, index) => (
      <button
        key={mediaIsVideo(media) ? media.url : media.properties.url}
        type="button"
        className={classnames(
          classes.slide,
          styles.slide,
          index === activeMediaIndex ? classes.activeSlide : undefined,
          mediaIsVideo(media) ? classes.slideVideo : undefined
        )}
        onClick={() => setActiveMediaIndex(index)}
      >
        <MediaThumbnail media={media} />
      </button>
    ));

  return (
    <div className={classnames(classes.root, styles.root)}>
      <div className={classnames(classes.activeContainer, styles.activeMediaContainer)}>
        {mediaIsVideo(activeMedia) ? (
          <FieldVideo
            isLazy={false}
            className={classnames(classes.activeMedia, classes.video)}
            id={extractVideoIdFromUrl(activeMedia?.url)}
          />
        ) : (
          <FieldImage
            className={classnames(classes.activeMedia, styles.activeMedia)}
            media={activeMedia}
          />
        )}
        {onActiveClick !== undefined && (
          <button
            type="button"
            className={classnames(classes.galleryBtn, styles.galleryBtn)}
            onClick={() => onActiveClick(activeMediaIndex)}
          >
            <Icon className={classnames(classes.galleryBtnIcon, styles.galleryIcon)} icon="show" />
          </button>
        )}
      </div>

      {medias.length > 1 && (
        <div
          className={classnames(
            classes.slider,
            styles.slider,
            medias.length <= 4 ? classes.noSlider : undefined,
            { [styles.noSlider]: medias.length <= 4 }
          )}
        >
          {medias.length > 4 ? (
            <SlickSlider {...sliderSettings}>{renderSlides(medias)}</SlickSlider>
          ) : (
            renderSlides(medias)
          )}
        </div>
      )}
    </div>
  );
}
