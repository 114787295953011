import React from 'react';
import { Link, Text, View } from '@react-pdf/renderer';

import { DOC_NATURE_PLAQUETTE } from 'settings/documents';
import { LABEL_PROGRAM_INFO_TITLE } from 'settings/labels';
import { PROGRAM_TAX_TYPE_FURNISHED } from 'settings/programs';

import { chunkify } from 'services/array';
import { getEntries } from 'services/programDatas';

import { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import styles from './ProgramPdfInfosStyles';

interface ProgramPdfInfosProps {
  program: ProgramTypeV2;
}

export default function ProgramPdfInfos({ program }: ProgramPdfInfosProps) {
  const isFurnished =
    program?.informationsFiscalesEtFinancieres.typeFiscalite === PROGRAM_TAX_TYPE_FURNISHED;

  const entries = getEntries(program, {
    type: true,
    manager: isFurnished,
    number: true,
    typology: true,
    address: true,
    product: !isFurnished,
    zone: false, // This used to be activated by Pinel
  });
  const chunks = chunkify(entries, 3, x => x);
  const booklet = program.documents.find(
    document => document.nature.toLocaleLowerCase() === DOC_NATURE_PLAQUETTE.toLocaleLowerCase()
  );

  return (
    <View style={styles.block}>
      <Text style={styles.title}>{LABEL_PROGRAM_INFO_TITLE}</Text>
      <View style={styles.infos}>
        {chunks.map(({ chunk, key }) => (
          <View key={key} style={styles.infosCol}>
            {chunk.map(
              ({ title, value }) =>
                value !== undefined && (
                  <View key={title} style={styles.info}>
                    <View style={styles.infoDot} />
                    <Text style={styles.infoLabel}>{title}</Text>
                    <Text style={styles.infoValue}>
                      {value.toString().replace(/[\r\n]+/gm, ' ')}
                    </Text>
                  </View>
                )
            )}
          </View>
        ))}
      </View>
      {booklet?.url && (
        <Text style={styles.booklet}>
          Plaquette commerciale -{' '}
          <Link style={styles.bookletLink} src={booklet.url}>
            {booklet.url}
          </Link>
        </Text>
      )}
    </View>
  );
}
