import React from 'react';
import classnames from 'classnames';

import LazyImage from 'commonUi/LazyImage/LazyImage';

import styles from './Contact.module.scss';

interface ContactProps {
  className?: string;
  description?: string;
  email?: string;
  firstName: string;
  image?: string;
  imageDimensions?: { width: string; height: string };
  lastName: string;
  phone?: string;
  picto?: string;
}

export default function Contact({
  className,
  description,
  email,
  firstName,
  image = '/images/placeholders/200x230.png',
  imageDimensions = { width: '200', height: '230' },
  lastName,
  phone,
  picto,
}: ContactProps) {
  return (
    <div className={classnames(className, styles.root)}>
      <LazyImage
        containerClassName={styles.imageContainer}
        className={styles.image}
        src={image}
        alt={`${firstName} ${lastName}`}
        width={Number(imageDimensions.width)}
        height={Number(imageDimensions.height)}
      />

      <div className={styles.name}>
        {firstName} {lastName}
      </div>
      <div className={styles.job}>
        {picto && <img className={styles.picto} src={picto} alt="" />}
        <span className={styles.text}>{description}</span>
      </div>

      {phone && (
        <a href={`tel:${phone}`} className={classnames(styles.phone, 'button', 'hoverTransparent')}>
          <img className="icon" src="/images/ico_phone_blanc.png" alt={phone} />
          {phone}
        </a>
      )}
      {email && (
        <a href={`mailto:${email}`} className={styles.email}>
          Contacter par mail
        </a>
      )}
    </div>
  );
}
