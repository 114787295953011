import React from 'react';
import type { ReactNode } from 'react';
import { get } from 'lodash';

import { LABEL_LOT_NUMBER } from 'settings/labels';
import { TAX_TYPE_LMNP } from 'settings/taxes';

import type { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import PastilleFiscality from 'commonUi/Pastille/PastilleFiscality';
import { LotPlan } from 'commonUi/LotPlan/LotPlan';
import { LotStatus } from 'commonUi/LotStatus/LotStatus';

import styles from './ComparisonCell.module.scss';

export const CELL_TYPE_FISCALITY = 'fiscality';
export const CELL_TYPE_LOT_LINK = 'lotLink';
export const CELL_TYPE_PLAN_LINK = 'planLink';
export const CELL_TYPE_PROGRAM_LINK = 'programLink';
export const CELL_TYPE_STATUS = 'status';

interface ComparisonCellProps {
  dataFrom?: string;
  dataProperty?: string;
  lot: LotTypeV2;
  program: ProgramTypeV2;
  type?: string;
  valueFormatter?: (value: any) => string | undefined;
  valueSuffix?: string;
}

export function ComparisonCell({
  dataFrom,
  dataProperty,
  lot,
  program,
  type,
  valueFormatter,
  valueSuffix = '',
}: ComparisonCellProps) {
  let cellContent: ReactNode;
  switch (type) {
    case CELL_TYPE_FISCALITY: {
      cellContent = <PastilleFiscality tax={lot.fiscalites[0]} ignore={TAX_TYPE_LMNP} />;
      break;
    }

    case CELL_TYPE_LOT_LINK:
      cellContent = (
        <a
          className={styles.lotLink}
          href={`/programme/page/${program.referenceProgramme}/prix?lotNumber=${lot.reference}&programRef=${program.referenceProgramme}`}
          rel="noreferrer"
          target="_blank"
        >
          {LABEL_LOT_NUMBER}
          {lot.reference}
        </a>
      );
      break;

    case CELL_TYPE_PLAN_LINK:
      cellContent = (
        <div className={styles.plan}>
          <LotPlan lot={lot} />
        </div>
      );
      break;

    case CELL_TYPE_PROGRAM_LINK:
      cellContent = (
        <a
          className={styles.programLink}
          href={`/programme/page/${program.referenceProgramme}/prix`}
          rel="noreferrer"
          target="_blank"
        >
          <div className={styles.programName}>{program.nomCommercial}</div>
          <div
            className={styles.programLocation}
          >{`${program.localisation.ville} (${program.localisation.codePostal})`}</div>
        </a>
      );
      break;

    case CELL_TYPE_STATUS:
      cellContent = (
        <div className={styles.status}>
          <LotStatus lot={lot} />
        </div>
      );
      break;

    default: {
      const value = dataProperty
        ? get(dataFrom === 'program' ? program : lot, dataProperty)
        : undefined;
      const str = valueFormatter ? valueFormatter(value) : value;
      cellContent = <div className={styles.value}>{str ? `${str}${valueSuffix}` : '-'}</div>;
      break;
    }
  }

  return (
    <td>
      <div className={styles.content}>{cellContent}</div>
    </td>
  );
}
