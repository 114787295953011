import React, { useContext, useEffect, useRef } from 'react';
import classnames from 'classnames';

import { replaceTokens } from 'services/formatter';
import { error } from 'services/log';
import { LABEL_VERSION } from 'settings/labels';
import { MENU_KEY_FOOTER } from 'settings/menus';
import { TOKEN_VERSION } from 'settings/token';

import type { MenuType } from 'api/vi3pAPI/apiTypes/MenuTypes';
import { useSWRVi3pImmutable } from 'api/vi3pAPI/useSWRVi3p';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';
import SettingsContext from 'modules/App/Contexts/SettingsContext';
import { TmsContext } from 'modules/App/Contexts/TmsContext';

import logoMobile from 'images/logo_vi3p_white.svg';

import FooterColumn from './FooterColumn';

import styles from './Footer.module.scss';

interface FooterProps {
  authenticated?: boolean;
}

export default function Footer({ authenticated = false }: FooterProps) {
  const { tmsIsReady } = useContext(TmsContext);
  const { isResponsive, setIsFooterVisibleMobile } = useContext(ResponsiveContext);
  const { settings } = useContext(SettingsContext);
  const footerRef = useRef<HTMLElement>(null);

  const { data: footerMenuItems } = useSWRVi3pImmutable<MenuType>({
    url: `/menu_link_content?id=${MENU_KEY_FOOTER}`,
  });

  useEffect(() => {
    function onScroll() {
      if (footerRef.current && footerRef.current.getBoundingClientRect().top < window.innerHeight) {
        setIsFooterVisibleMobile(true);
      } else {
        setIsFooterVisibleMobile(false);
      }
    }

    if (isResponsive) {
      document.addEventListener('scroll', onScroll);
      return () => document.removeEventListener('scroll', onScroll);
    }
    return undefined;
  });

  return (
    <footer
      className={classnames(styles.root, {
        [styles.authenticated]: authenticated,
      })}
      ref={footerRef}
    >
      {!!settings.footer?.blocs.length && (
        <div className={styles.top}>
          <div className={styles.columns}>
            {settings.footer.blocs.map((column, index) => (
              <FooterColumn
                key={column.titre}
                classes={
                  index === 0
                    ? { root: styles.about, picto: styles.picto, content: styles.content }
                    : undefined
                }
                column={column}
              />
            ))}
          </div>
        </div>
      )}

      <img className={styles.logo} src={logoMobile} alt="" />

      <div className={styles.bottom}>
        {settings.footer?.copyright && (
          <div className={styles.copyright}>{settings.footer.copyright}</div>
        )}
        <div className={styles.version}>
          {replaceTokens(LABEL_VERSION, { [TOKEN_VERSION]: window.vinci.REACT_APP_VERSION })}
        </div>

        {!!footerMenuItems?.[MENU_KEY_FOOTER]?.length && (
          <ul className={styles.links}>
            {footerMenuItems[MENU_KEY_FOOTER].map(item => {
              if (item.tmsReady && !tmsIsReady) {
                return null;
              }

              if (item.privacy_center) {
                return (
                  <li key={item.mid}>
                    <button
                      type="button"
                      onClick={() => {
                        try {
                          window.tC.privacyCenter.showPrivacyCenter();
                        } catch (e) {
                          error('TMS', e);
                        }
                      }}
                    >
                      {item.name}
                    </button>
                  </li>
                );
              }

              return (
                <li key={item.mid}>
                  <a href={item.url} target={item.target}>
                    {item.name}
                  </a>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </footer>
  );
}
