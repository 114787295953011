import React from 'react';

import {
  LABEL_FINANCIAL_INFORMATIONS,
  LABEL_KNOW_MORE,
  LABEL_NUDE_PROPERTY_TTC,
  LABEL_PRICE_IMMO_HT,
  LABEL_PRICE_INCLUDING_TAX_TTC,
  LABEL_PRICE_MOBILIER_HT,
  LABEL_PRICE_TTC_VAT_NORMAL,
  LABEL_PRICE_TTC_VAT_REDUCED,
  LABEL_PROFITABILITY,
  LABEL_PROFITABILITY_HT,
  LABEL_RENT_LMNP_FURNISHED,
  LABEL_RENT_LMNP_UNFURNISHED,
  LABEL_RENT_MARKET_MONTH,
  LABEL_RENT_YEARLY,
  LABEL_SECURITY_DEPOSIT,
} from 'settings/labels';
import { LOT_DEFAULT_SECURITY_DEPOSIT } from 'settings/lots';
import {
  TAX_TYPE_DEMEMBREMENT,
  TAX_TYPE_LMNP,
  TAX_TYPE_LMNP_MANAGED,
  TAX_TYPE_NONE,
} from 'settings/taxes';

import type { TaxTaxonomyTerm } from 'api/viOffresAPI/apiTypes/Taxonomies';
import type { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import { normalizeTax } from 'services/taxes';
import { formatPrice } from 'services/formatter';

import RowDetailsList from 'commonUi/RowDetailsList/RowDetailsList';
import DepositInfos from 'commonUi/DepositInfos/DepositInfos';

interface LotDetailsFinancialType {
  classes?: {
    root?: string;
    title?: string;
  };
  fiscality?: TaxTaxonomyTerm;
  lot: LotTypeV2;
  program: ProgramTypeV2;
}

export default function LotDetailsFinancial({
  classes = {},
  fiscality,
  lot,
  program,
}: LotDetailsFinancialType) {
  const taxType = normalizeTax(fiscality?.nom);
  const isLmnpSimple = taxType === TAX_TYPE_LMNP;
  const isLmnpManaged = taxType === TAX_TYPE_LMNP_MANAGED;
  const isDemembrement = taxType === TAX_TYPE_DEMEMBREMENT;
  const isNoTax = taxType === TAX_TYPE_NONE;
  const {
    bicDepotGarantie,
    ibanDepotGarantie,
    modePaiement,
    nomBanqueDepotGarantie,
    ordreDepotGarantie,
  } = program.depotGarantie;

  let profitability = parseFloat(lot.investissementLocatif.rendementLocatif ?? '');
  if (isLmnpManaged) {
    profitability = parseFloat(lot.investissementLocatif.rentabiliteLMNP ?? '');
  }

  return (
    <div className={classes?.root}>
      <div className={classes?.title}>{LABEL_FINANCIAL_INFORMATIONS}</div>
      <RowDetailsList
        items={{
          ...(isNoTax
            ? {
                [LABEL_RENT_MARKET_MONTH]: {
                  label: LABEL_RENT_MARKET_MONTH,
                  value: formatPrice(lot?.investissementLocatif?.loyerMarcheTTC),
                },
              }
            : {}),
          ...(isLmnpManaged
            ? {
                [LABEL_PRICE_IMMO_HT]: {
                  label: LABEL_PRICE_IMMO_HT,
                  value: formatPrice(lot?.prix?.prixHTHorsMobilier),
                },
                [LABEL_PRICE_MOBILIER_HT]: {
                  label: LABEL_PRICE_MOBILIER_HT,
                  value: formatPrice(lot?.prix?.prixMobilierHT),
                },
                [LABEL_RENT_YEARLY]: {
                  label: LABEL_RENT_YEARLY,
                  value: formatPrice(lot?.investissementLocatif?.loyerMarcheAnnuelHT),
                },
              }
            : {}),
          ...(isLmnpSimple
            ? {
                [LABEL_PRICE_TTC_VAT_REDUCED]: {
                  label: LABEL_PRICE_TTC_VAT_REDUCED,
                  value: formatPrice(lot.prix.TVAalternative?.prixTTC),
                },
                [LABEL_PRICE_TTC_VAT_NORMAL]: {
                  label: LABEL_PRICE_TTC_VAT_NORMAL,
                  value: formatPrice(lot.prix.TVANormale.prixTTC),
                },
                [LABEL_RENT_LMNP_UNFURNISHED]: {
                  label: LABEL_RENT_LMNP_UNFURNISHED,
                  value: formatPrice(lot.investissementLocatif.loyerMarcheTTC),
                },
                [LABEL_RENT_LMNP_FURNISHED]: {
                  label: LABEL_RENT_LMNP_FURNISHED,
                  value: formatPrice(lot.investissementLocatif.loyerBailMensuel),
                },
              }
            : {
                [LABEL_PROFITABILITY]: {
                  label: isLmnpManaged ? LABEL_PROFITABILITY_HT : LABEL_PROFITABILITY,
                  value: profitability > 0 ? `${profitability}%` : '-',
                },
              }),
          ...(isDemembrement
            ? {
                [LABEL_RENT_MARKET_MONTH]: {
                  label: `${LABEL_NUDE_PROPERTY_TTC} = ${LABEL_PRICE_INCLUDING_TAX_TTC}`,
                  value: LABEL_KNOW_MORE,
                  href: `/programme/page/${program.referenceProgramme}/prix`,
                },
              }
            : {}),
          [LABEL_SECURITY_DEPOSIT]: {
            label: LABEL_SECURITY_DEPOSIT,
            value: program?.depotGarantie?.montantDepotGarantie
              ? formatPrice(program.depotGarantie.montantDepotGarantie)
              : LOT_DEFAULT_SECURITY_DEPOSIT,
            infos: (
              <DepositInfos
                bicDepotGarantie={bicDepotGarantie}
                ibanDepotGarantie={ibanDepotGarantie}
                ordreDepotGarantie={ordreDepotGarantie}
                nomBanqueDepotGarantie={nomBanqueDepotGarantie}
                modePaiement={modePaiement}
                montantDepot={
                  program?.depotGarantie?.montantDepotGarantie
                    ? formatPrice(program.depotGarantie.montantDepotGarantie)
                    : LOT_DEFAULT_SECURITY_DEPOSIT
                }
              />
            ),
          },
        }}
      />
    </div>
  );
}
