import {
  ACTIVITY_STATUS_ACTIVE,
  ACTIVITY_STATUS_CANCELED,
  ACTIVITY_STATUS_LOST,
  ACTIVITY_STATUS_WON,
} from 'settings/activity';
import {
  LOT_STATUS_BOOKED,
  LOT_STATUS_FREE,
  LOT_STATUS_OPTIONED,
  LOT_STATUS_PRE_BOOKED,
} from 'settings/lots';

export const PASTILLE_STATUS_COLOR = {
  // Lot statuses
  [LOT_STATUS_FREE]: { bgColor: '#DAFAE1', textColor: '#0FA669' },
  [LOT_STATUS_OPTIONED]: { bgColor: '#FFF3E8', textColor: '#F99536' },
  [LOT_STATUS_PRE_BOOKED]: { bgColor: '#FFE8E7', textColor: '#FE607D' },
  [LOT_STATUS_BOOKED]: { bgColor: '#FFE8E7', textColor: '#FE607D' },

  // Activity statuses
  [ACTIVITY_STATUS_ACTIVE]: { bgColor: '#E8F7FF', textColor: '#4FBFE2' },
  [ACTIVITY_STATUS_CANCELED]: { bgColor: '#F3F3F3', textColor: '#AAA' },
  [ACTIVITY_STATUS_LOST]: { bgColor: '#F3F3F3', textColor: '#AAA' },
  [ACTIVITY_STATUS_WON]: { bgColor: '#E0F8EB', textColor: '#0FA669' },
};

export const PASTILLE_STATUS_ICON = {
  [LOT_STATUS_FREE]: 'check-circle-vi3p',
  [LOT_STATUS_OPTIONED]: 'option',
  [LOT_STATUS_PRE_BOOKED]: 'pre-book',
  [LOT_STATUS_BOOKED]: 'reservation-contract',
};
