import React, { useMemo } from 'react';
import type { PropsWithChildren } from 'react';
import useSWRImmutable from 'swr/immutable';

import type {
  AnnexTaxonomyTerm,
  KindTaxonomyTerm,
  TaxTaxonomyTerm,
} from 'api/viOffresAPI/apiTypes/Taxonomies';

import { normalizeTax } from 'services/taxes';

import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import { axiosViOffreInstance } from 'api/viOffresAPI/axiosInstance';

const DEFAULT_YEARS_ITEM = {
  label: 'Immédiate',
  value: 'now',
};

export default function TaxonomiesProvider({ children }: PropsWithChildren<Record<never, never>>) {
  const {
    data: annexes,
    isLoading: isAnnexesLoading,
  } = useSWRImmutable('taxonomies/normalisation_annexes?site=vi3p', url =>
    axiosViOffreInstance.get<AnnexTaxonomyTerm[]>(url).then(response => response.data || [])
  );
  const {
    data: kinds,
    isLoading: isKindsLoading,
  } = useSWRImmutable('taxonomies/normalisation_type_de_lots?site=vi3p', url =>
    axiosViOffreInstance.get<KindTaxonomyTerm[]>(url).then(response => response.data || [])
  );
  const {
    data: taxTypes,
    isLoading: isTaxesLoading,
  } = useSWRImmutable('taxonomies/fiscalite?site=vi3p', url =>
    axiosViOffreInstance.get<TaxTaxonomyTerm[]>(url).then(response => response.data || [])
  );
  const {
    data: years,
    isLoading: isYearsLoading,
  } = useSWRImmutable('programs/criteria/year?site=vi3p', url =>
    axiosViOffreInstance
      .get<string[]>(url)
      .then(response => [DEFAULT_YEARS_ITEM, ...response.data.map(v => ({ label: v, value: v }))])
  );

  return (
    <TaxonomiesContext.Provider
      value={{
        annexes: annexes ?? [],
        isAnnexesLoading,
        isKindsLoading,
        isTaxesLoading,
        isYearsLoading,
        kinds: kinds ?? [],
        taxes: taxTypes ?? [],
        taxesById: useMemo(
          () =>
            taxTypes
              ? Object.fromEntries(
                  taxTypes.map(term => [
                    term.id,
                    normalizeTax(term.nom) as NonNullable<ReturnType<typeof normalizeTax>>,
                  ])
                )
              : {},
          [taxTypes]
        ),
        years: years ?? [DEFAULT_YEARS_ITEM],
      }}
    >
      {children}
    </TaxonomiesContext.Provider>
  );
}
