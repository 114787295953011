import React from 'react';
import type { ImgHTMLAttributes } from 'react';
import LazyLoad from 'react-lazyload';
import type { LazyLoadProps } from 'react-lazyload';
import classnames from 'classnames';

import styles from './LazyImage.module.scss';

interface LazyImageProps
  extends ImgHTMLAttributes<HTMLImageElement>,
    Pick<LazyLoadProps, 'offset'> {
  containerClassName?: string;
}

export default function LazyImage({
  alt,
  className,
  containerClassName,
  offset = 500,
  src,
  width,
  height,
  ...imgProps
}: LazyImageProps) {
  const showPlaceholder = !Number.isNaN(Number(width)) && !Number.isNaN(Number(height));

  return (
    <LazyLoad
      once
      className={classnames(containerClassName, styles.root, {
        [styles.placeholder]: showPlaceholder,
      })}
      offset={offset}
      style={
        showPlaceholder ? { paddingTop: `${(100 * Number(height)) / Number(width)}%` } : undefined
      }
    >
      <img
        className={classnames(className, styles.image)}
        alt={alt}
        src={src}
        width={width}
        height={height}
        {...imgProps}
      />
    </LazyLoad>
  );
}
