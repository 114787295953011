import React, { useContext, useState } from 'react';
import Slider from 'react-slick';
import { CircularProgress as MuiCircularProgress } from '@material-ui/core';
import classnames from 'classnames';

import type { HomeProgram as HomeProgramType } from 'api/viOffresAPI/apiTypes/Program';

import { useSWROffresImmutable } from 'api/offresAPI/useSWROffres';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';

import HomeProgram from './HomeProgram';
import HomeProgramsModal from './HomeProgramsModal';

import styles from './HomePrograms.module.scss';

const LOGIN_URL = window.vinci.REACT_APP_LOGIN_URL;
const SIGNUP_URL = window.vinci.REACT_APP_SIGNUP_URL;

interface HomeProgramsProps {
  className?: string;
  title?: string;
}

export default function HomePrograms({ className, title }: HomeProgramsProps) {
  const { isResponsive } = useContext(ResponsiveContext);
  const [activeProgram, setActiveProgram] = useState<HomeProgramType>();
  const { data: programs, isLoading } = useSWROffresImmutable<HomeProgramType[]>({
    url: '/opportunites',
  });

  const renderPrograms = (programs: HomeProgramType[]) =>
    programs.map(program => (
      <HomeProgram
        key={program.nomCommercial}
        className={styles.program}
        program={program}
        // On click clone program object so the modal re-opens if we click the same program again
        onClick={() => setActiveProgram({ ...program })}
      />
    ));

  return (
    <div className={classnames(className, styles.root)}>
      {title && (isLoading || !!programs?.length) && <h2 className={styles.title}>{title}</h2>}

      {isLoading && (
        <div className={styles.loaderContainer}>
          <MuiCircularProgress
            classes={{ root: styles.loader }}
            variant="indeterminate"
            size={32}
          />
        </div>
      )}

      {!isLoading && !!programs?.length && (
        <div className={styles.list}>
          {isResponsive ? (
            <Slider
              className={styles.slider}
              arrows={false}
              dots
              dotsClass={classnames(styles.dots, 'slider-dots', 'white')}
              infinite={false}
              slidesToScroll={1}
              variableWidth
            >
              {renderPrograms(programs)}
            </Slider>
          ) : (
            renderPrograms(programs)
          )}
          <HomeProgramsModal program={activeProgram} />
        </div>
      )}

      <div className={styles.login}>
        <div className={styles.text}>
          Pour voir l’ensemble de nos programmes, connectez-vous&nbsp;:
        </div>

        <div className={styles.buttons}>
          <a className={classnames(styles.link, 'button', 'hoverTransparent')} href={LOGIN_URL}>
            Je me connecte
          </a>
          <a className={classnames(styles.link, 'button', 'transparent')} href={SIGNUP_URL}>
            Je deviens partenaire
          </a>
        </div>
      </div>
    </div>
  );
}
