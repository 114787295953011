import {
  LOT_STATUS_BOOKED,
  LOT_STATUS_FREE,
  LOT_STATUS_OPTIONED,
  LOT_STATUS_PRE_BOOKED,
} from 'settings/lots';
import {
  TAX_TYPE_BRS,
  TAX_TYPE_DEMEMBREMENT,
  TAX_TYPE_LMNP,
  TAX_TYPE_LMNP_MANAGED,
  TAX_TYPE_MALRAUX,
  TAX_TYPE_NONE,
  TAX_TYPE_PLS,
} from 'settings/taxes';

export const PDF_PASTILLE_STATUS_ICON = {
  [LOT_STATUS_FREE]: 'statusFree',
  [LOT_STATUS_OPTIONED]: 'statusOption',
  [LOT_STATUS_PRE_BOOKED]: 'statusPreBook',
  [LOT_STATUS_BOOKED]: 'statusPreBook',
};

export const PDF_PASTILLE_STATUS_ICON_WIDTHS = {
  [LOT_STATUS_FREE]: '9px',
  [LOT_STATUS_OPTIONED]: '10px',
  [LOT_STATUS_PRE_BOOKED]: '10px',
  [LOT_STATUS_BOOKED]: '10px',
};

export const PDF_PASTILLE_STATUS_ICON_WIDTHS_REDUCED = {
  [LOT_STATUS_FREE]: '5px',
  [LOT_STATUS_OPTIONED]: '5px',
  [LOT_STATUS_PRE_BOOKED]: '5px',
  [LOT_STATUS_BOOKED]: '5px',
};

export const PDF_TAX_ICON = {
  [TAX_TYPE_LMNP]: 'fiscalityLmnp',
  [TAX_TYPE_LMNP_MANAGED]: 'fiscalityLmnpGere',
  [TAX_TYPE_MALRAUX]: 'fiscalityMalraux',
  [TAX_TYPE_NONE]: 'fiscalityHorsDispositif',
  [TAX_TYPE_DEMEMBREMENT]: 'fiscalityNuePropriete',
  [TAX_TYPE_BRS]: 'fiscalityBrs',
  [TAX_TYPE_PLS]: 'fiscalityPls',
};
