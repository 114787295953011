export const TAX_TYPE_NONE = 'Hors dispositif';
export const TAX_TYPE_DEMEMBREMENT = 'Démembrement';
export const TAX_TYPE_LMNP = 'LMNP';
export const TAX_TYPE_LMNP_MANAGED = 'LMNP géré';
export const TAX_TYPE_DEFICIT = 'Déficit Foncier';
export const TAX_TYPE_MALRAUX = 'Malraux';
export const TAX_TYPE_BRS = 'Bail Réel Solidaire';
export const TAX_TYPE_PLS = 'Prêt Locatif Social';

export const TAX_LABEL_TYPE_NONE = 'Hors dispositif';
export const TAX_LABEL_TYPE_DEMEMBREMENT = 'Nue Propriété';
export const TAX_LABEL_TYPE_LMNP = 'LMNP';
export const TAX_LABEL_TYPE_DEFICIT = 'Déficit Foncier';
export const TAX_LABEL_TYPE_MALRAUX = 'Malraux';
export const TAX_LABEL_TYPE_BRS = 'Bail Réel Solidaire';
export const TAX_LABEL_TYPE_PLS = 'Prêt Locatif Social';
