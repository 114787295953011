import React from 'react';
import classnames from 'classnames';

import {
  TAX_TYPE_BRS,
  TAX_TYPE_DEMEMBREMENT,
  TAX_TYPE_LMNP,
  TAX_TYPE_LMNP_MANAGED,
  TAX_TYPE_MALRAUX,
  TAX_TYPE_PLS,
} from 'settings/taxes';

import { normalizeTax } from 'services/taxes';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './PastilleFiscality.module.scss';

const TAX_ICON = {
  // [TAX_LABEL_TYPE_NONE] is voluntarily omitted so the "Hors Dispositif" picto is not shown
  [TAX_TYPE_LMNP]: 'icon-fiscality-lmnp',
  [TAX_TYPE_LMNP_MANAGED]: 'icon-fiscality-lmnp-gere',
  [TAX_TYPE_DEMEMBREMENT]: 'icon-fiscality-nue-propriete',
  [TAX_TYPE_BRS]: 'icon-fiscality-brs',
  [TAX_TYPE_PLS]: 'icon-fiscality-pls',
  [TAX_TYPE_MALRAUX]: 'icon-fiscality-malraux',
};

export interface PastilleFiscalityProps {
  className?: string;
  ignore?: string[] | string;
  tax: string | undefined;
}

export default function PastilleFiscality({ className, ignore, tax }: PastilleFiscalityProps) {
  const normalized = normalizeTax(tax);
  if (
    !normalized ||
    !TAX_ICON[normalized] ||
    (Array.isArray(ignore) ? ignore.includes(normalized) : ignore === normalized)
  ) {
    return null;
  }
  return <SvgIcon className={classnames(className, styles.root)} iconId={TAX_ICON[normalized]} />;
}
