import React from 'react';
import type { ComponentProps } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { formatPrice } from 'services/formatter';
import { htmlEntityDecode } from 'services/stringHtml';
import {
  ACTIVITY_CELL_TYPE_ACTIONS,
  ACTIVITY_CELL_TYPE_DATE,
  ACTIVITY_CELL_TYPE_DATE_TIME,
  ACTIVITY_CELL_TYPE_DEFAULT,
  ACTIVITY_CELL_TYPE_LOT,
  ACTIVITY_CELL_TYPE_NAME,
  ACTIVITY_CELL_TYPE_PRICE,
  ACTIVITY_CELL_TYPE_PROGRAM,
  ACTIVITY_CELL_TYPE_STATUS,
  ACTIVITY_CELL_TYPE_TAX,
  ACTIVITY_STATUS_WON,
  ACTIVITY_TYPE_BOOKING,
  ACTIVITY_TYPE_SALE,
  ACTIVITY_TYPE_TO_TAB_ID_MAPPING,
} from 'settings/activity';
import { TAX_TYPE_LMNP } from 'settings/taxes';

import type { Activity, ActivityHeading } from 'api/vi3pAPI/apiTypes/ActivityType';
import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';

import PastilleFiscality from 'commonUi/Pastille/PastilleFiscality';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import Tooltip from 'commonUi/Tooltip/Tooltip';

import ActivityActions from '../ActivityTable/ActivityActions';
import ActivityStatus from '../ActivityTable/ActivityStatus';

import styles from './ActivityCell.module.scss';

interface ActivityCellProps {
  activity: Activity;
  apiUrls: ComponentProps<typeof ActivityActions>['apiUrls'];
  changeTab: ComponentProps<typeof ActivityStatus>['changeTab'];
  col: ActivityHeading;
  hover?: boolean;
  program?: ProgramListType;
}

export default function ActivityCell({
  activity,
  apiUrls,
  changeTab,
  col,
  program,
  hover = false,
}: ActivityCellProps) {
  switch (col.type) {
    case ACTIVITY_CELL_TYPE_NAME: {
      const values = col.field.map(f => activity[f]).filter(Boolean);
      return <div>{values.length ? values.join(' ') : '-'}</div>;
    }

    case ACTIVITY_CELL_TYPE_PRICE:
      return (
        <div>
          {col.field && activity[col.field]
            ? formatPrice(activity[col.field] as string | number)
            : '-'}
        </div>
      );

    case ACTIVITY_CELL_TYPE_PROGRAM:
      return (
        <>
          <Link
            className={styles.programContainer}
            to={`/programme/page/${activity.field_referenceprogramme_ac}/prix`}
            target="_blank"
            onClick={event => event.stopPropagation()}
          >
            <span className={styles.programName}>
              {htmlEntityDecode(activity.field_nomprogramme_ac)}
            </span>
            {program?.name && program?.city && program?.postalCode && (
              <span className={styles.programLocation}>
                {htmlEntityDecode(program.city)}{' '}
                {program.postalCode && `(${htmlEntityDecode(program.postalCode)})`}
              </span>
            )}
          </Link>
          {program?.reports && (
            <Tooltip arrow placement="top" title="Accéder au suivi du chantier">
              <Link
                className={styles.programReports}
                to={`/programme/page/${activity.field_referenceprogramme_ac}/chantiers?from=${
                  ACTIVITY_TYPE_TO_TAB_ID_MAPPING[activity.field_type_ac]
                }`}
                target="_blank"
                onClick={ev => ev.stopPropagation()}
              >
                <SvgIcon className={styles.icon} iconId="icon-progress-bicolor" />
              </Link>
            </Tooltip>
          )}
        </>
      );

    case ACTIVITY_CELL_TYPE_LOT:
      return activity.field_numerolot_ac ? (
        <div className={styles.lot}>{activity.field_numerolot_ac}</div>
      ) : null;

    case ACTIVITY_CELL_TYPE_STATUS:
      return (
        <ActivityStatus
          showSteps
          hover={hover}
          activity={activity}
          changeTab={changeTab}
          displayColumn={
            activity.field_type_ac === ACTIVITY_TYPE_SALE &&
            activity.field_statutnom_ac === ACTIVITY_STATUS_WON
          }
          showStepsForWon={
            activity.field_type_ac === ACTIVITY_TYPE_BOOKING ||
            activity.field_type_ac === ACTIVITY_TYPE_SALE
          }
        />
      );

    case ACTIVITY_CELL_TYPE_TAX:
      return <PastilleFiscality tax={activity.field_dispositiffiscal_ac} ignore={TAX_TYPE_LMNP} />;

    case ACTIVITY_CELL_TYPE_ACTIONS:
      return <ActivityActions activity={activity} apiUrls={apiUrls} />;

    case ACTIVITY_CELL_TYPE_DATE:
      return (
        <div>
          {col.field && activity[col.field]
            ? dayjs(activity[col.field] as string).format('DD/MM/YYYY')
            : '-'}
        </div>
      );

    case ACTIVITY_CELL_TYPE_DATE_TIME:
      return (
        <div>
          {col.field && activity[col.field]
            ? dayjs(activity[col.field] as string).format('DD/MM/YYYY HH:mm')
            : '-'}
        </div>
      );

    case ACTIVITY_CELL_TYPE_DEFAULT:
      if (col.unit) {
        return (
          <div>{col.field && activity[col.field] ? `${activity[col.field]}${col.unit}` : '-'}</div>
        );
      }
      return <div>{activity[col.field ?? ''] ?? '-'}</div>;

    default:
  }
  const exhaustivenessCheck: never = col;
  return exhaustivenessCheck;
}
