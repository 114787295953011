import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  FIELD_FISCALITY,
  KEY_PREBOOK_PURCHASE_DESTINATION_FIRSTIME_BUYER,
  KEY_PREBOOK_PURCHASE_DESTINATION_INVESTOR,
  KEY_PREBOOK_PURCHASE_DESTINATION_PRINCIPAL_RESIDENCE,
  KEY_PREBOOK_PURCHASE_DESTINATION_SECOND_RESIDENCE,
} from 'settings/forms';
import { LABEL_NO, LABEL_YES } from 'settings/labels';

import { taxPayloadNames } from 'services/taxes';
import type { normalizeTax } from 'services/taxes';

import RadioGroup from 'commonUi/RadioButton/RadioGroup';
import RadioLabel from 'commonUi/RadioButton/RadioLabel';

import styles from '../PageAction.module.scss';

interface FieldFiscalityProps {
  normalizedTax: ReturnType<typeof normalizeTax>;
}

export function FieldFiscality({ normalizedTax }: FieldFiscalityProps) {
  const { control, setValue, watch } = useFormContext();
  const destinationFieldValue = watch('field-purchase-destination');
  const value = normalizedTax ? taxPayloadNames[normalizedTax] : undefined;

  useEffect(() => {
    if (
      destinationFieldValue === KEY_PREBOOK_PURCHASE_DESTINATION_FIRSTIME_BUYER ||
      destinationFieldValue === KEY_PREBOOK_PURCHASE_DESTINATION_PRINCIPAL_RESIDENCE
    ) {
      setValue(FIELD_FISCALITY, 'aucun');
    } else if (
      destinationFieldValue === KEY_PREBOOK_PURCHASE_DESTINATION_INVESTOR ||
      destinationFieldValue === KEY_PREBOOK_PURCHASE_DESTINATION_SECOND_RESIDENCE
    ) {
      setValue(FIELD_FISCALITY, value, { shouldDirty: true });
    }
  }, [destinationFieldValue]);

  if (!value) {
    return null;
  }

  return (
    <div className={styles.fiscalityRadio}>
      <Controller
        name={FIELD_FISCALITY}
        control={control}
        defaultValue={null}
        render={props => (
          <RadioGroup
            value={props?.field?.value}
            onChange={ev => {
              props?.field?.onChange(ev.target.value);
            }}
            radioLabel={
              <>
                <RadioLabel value={value} label={LABEL_YES} />
                <RadioLabel value="aucun" label={LABEL_NO} />
              </>
            }
          />
        )}
      />
    </div>
  );
}
