import React from 'react';
import LazyLoad from 'react-lazyload';

import YouTube from 'commonUi/YouTube/YouTube';

interface FieldVideoProps {
  className?: string;
  id: string | undefined;
  isLazy?: boolean;
  lazyClassName?: string;
}

export default function FieldVideo({
  className = undefined,
  id,
  isLazy = true,
  lazyClassName = undefined,
}: FieldVideoProps) {
  if (!id) {
    return null;
  }

  const videoRendered = (
    <YouTube
      className={className}
      videoId={id}
      opts={{
        height: '100%',
        width: '100%',
        playerVars: {
          controls: 0,
          enablejsapi: 1,
          rel: 0,
        },
      }}
    />
  );

  return isLazy ? (
    <LazyLoad once className={lazyClassName} offset={500}>
      {videoRendered}
    </LazyLoad>
  ) : (
    videoRendered
  );
}
