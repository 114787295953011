import React, { useEffect, useRef, useState } from 'react';
import type { ComponentProps } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import qs from 'query-string';

import { isAuthenticated, isInvite } from 'services/authentication';

import AuthenticatedLayout from 'layout/AuthenticatedLayout';
import GuestLayout from 'layout/GuestLayout';
import PendingValidation from 'modules/Authentication/PendingValidation';
import TagCommander from 'modules/App/TagCommander/TagCommander';
import HomeBanner from 'commonUi/Home/Banner';
import MediaBlock from 'commonUi/Home/MediaBlock';
import ContactsSlider from 'commonUi/Home/ContactsSlider';
import HomePrograms from 'commonUi/Home/Programs';
import News from 'modules/News';

import ErrorBoundary from './Tools/ErrorBoundary';

export default function HomeRoute(props: ComponentProps<typeof Route>) {
  const bannerRef = useRef<HTMLDivElement>(null);
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const scrollTarget = useRef<HTMLElement>(null);
  const location = useLocation();
  const params = qs.parse(location.search);

  useEffect(() => {
    // Show/Hide the header based on visibility of the Page's Banner against the scrolling viewport
    if (bannerRef.current) {
      const observer = new IntersectionObserver(
        entries =>
          entries.forEach(entry => {
            if (entry.target === bannerRef.current) {
              setIsBannerVisible(entry.isIntersecting);
            }
          }),
        {
          root: null, // root: null to measure intersection against viewport
          rootMargin: '0px',
          threshold: 0, // threshold: 0 it intersects as soon as 1px is within the viewport
        }
      );

      observer.observe(bannerRef.current);
      return () => observer.disconnect();
    }

    return undefined;
  }, []);

  if (isAuthenticated()) {
    if (isInvite()) {
      return (
        <AuthenticatedLayout hideFooter>
          <ErrorBoundary location={location}>
            <PendingValidation />
          </ErrorBoundary>
        </AuthenticatedLayout>
      );
    }

    return <Redirect to={`/recherche${params?.video === 'open' ? '?video=open' : ''}`} />;
  }

  return (
    <Route {...props}>
      <GuestLayout
        isHeaderVisible={!isBannerVisible}
        onScrollerClick={() => {
          if (scrollTarget.current) {
            window.scrollTo({
              top: scrollTarget.current.offsetTop,
              behavior: 'smooth',
            });
          }
        }}
      >
        <HomeBanner ref={bannerRef} />
        <MediaBlock />
        <HomePrograms title="Découvrez nos programmes exclusifs" />
        <News
          title="Restez informés des dernières tendances du marché immobilier"
          visibleItems={5}
        />
        <ContactsSlider ref={scrollTarget} />
        <TagCommander navigation_pagename="homepage" navigation_template="homepage" />
      </GuestLayout>
    </Route>
  );
}
